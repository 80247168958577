import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Icon,
  Image,
  Link,
  Stack,
} from "@chakra-ui/react";

import LogoEtank from "../../assets/logo-branco.png";
import { RiAddFill, RiSubtractFill } from "react-icons/ri";
import { useState } from "react";

const linksMenu = [
  {
    icon: "icon",
    link: "/home",
    title: "DASHBOARD",
    index: 0,
    submenu: [
      {
        icon: "icon",
        link: "/home",
        title: "REQUISIÇÕES",
      },
    ],
  },
  {
    icon: "icon",
    link: "create",
    title: "CADASTROS",
    index: 1,
    submenu: [
      {
        icon: "icon",
        link: "/produtos-servicos",
        title: "PRODUTOS/SERVIÇOS",
      },
      {
        icon: "icon",
        link: "/atualizar-precos",
        title: "ATUALIZAÇÃO DE PREÇOS",
      },
      {
        icon: "icon",
        link: "/clientes",
        title: "CONTROLE DE CLIENTES",
      },
      {
        icon: "icon",
        link: "/usuarios",
        title: "USUÁRIOS",
      },
    ],
  },
  {
    icon: "icon",
    link: "/login",
    title: "RELATÓRIOS",
    index: 2,
    submenu: [
      {
        icon: "icon",
        link: "/requisicoes-atendidas",
        title: "REQUISIÇÕES ATENDIDAS",
      },
    ],
  },
];

type Props = {
  path?: string;
};

export function Sidebar({ path }: Props) {
  const [accordionIndex, setAccordionIndex] = useState<number>(-1);

  function getIndex() {
    const index = linksMenu.find((e) =>
      e.submenu.find((e) => e.link === path)
    )?.index;
    return index;
  }

  return (
    <Box
      as="aside"
      bg="green.400"
      width="300px"
      minH="80vh"
      boxShadow="base"
      borderBottomWidth={1}
      borderBottomColor="green.400"
    >
      <Box
        display="flex"
        height="82px"
        width="100%"
        bg="green.600"
        alignItems="center"
        justifyContent="center"
      >
        <Image src={LogoEtank} height="30px" alt="Etank" />
      </Box>
      <Stack spacing="12" width="100%" align="flex-start">
        <Accordion
          allowToggle
          width="100%"
          index={accordionIndex === -1 ? getIndex() : accordionIndex}
          onChange={(expIndex: any) => {
            setAccordionIndex(expIndex);
            console.log(expIndex);
          }}
        >
          {linksMenu.map((item, index) => (
            <AccordionItem key={index} border="none">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    display="flex"
                    justifyContent="space-between"
                    color="white"
                    fontWeight="bold"
                    _expanded={{
                      fontWeight: "bold",
                      color: "white",
                      bg: "green.600",
                    }}
                    _hover={{
                      fontWeight: "bold",
                      color: "white",
                      bg: "green.400",
                    }}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      {item.title}
                    </Box>

                    {isExpanded ? (
                      <Icon color="white" fontSize="20px" as={RiSubtractFill} />
                    ) : (
                      <Icon color="white" fontSize="20px" as={RiAddFill} />
                    )}
                  </AccordionButton>

                  <AccordionPanel pb={4} bg="green.400" p={0}>
                    <Stack align="stretch">
                      {item.submenu.map((submenuItem, indexSub) => (
                        <Link
                          key={indexSub}
                          borderBottomColor="green.600"
                          borderBottomWidth={1}
                          px={5}
                          py={2}
                          color={
                            path === submenuItem.link ? "green.800" : "white"
                          }
                          fontWeight={
                            path === submenuItem.link ? "bold" : "normal"
                          }
                          href={submenuItem.link}
                        >
                          {submenuItem.title}
                        </Link>
                      ))}
                    </Stack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Stack>
    </Box>
  );
}
