import { useEffect, useState } from "react";
import { Box, Button, Checkbox, Flex, Text } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { InputSelect } from "../../../../components/form/inputSelect";
import { InputLine } from "../../../../components/form/InputLine";
import { InputPrice } from "../../../../components/form/inputPrice";
import { api } from "../../../../service/api";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";

export type createClientFormData = {
  office_branch_id: string;
  clent_name: string;
  contract_number: string;
  form_of_payment: string;
  signature_data: string;
  due_date?: string;
  discount: number;
  discount_start: string;
  discount_end: string;
  description?: string;
  indefinite_time: boolean;
  automatic_blocking?: boolean;
  is_blocked: boolean;
  credit_balance: number;
  credit_amount_used: number;
  credit_total_balance: number;
};

interface createClientData {
  office_branch_id: string;
  contract_number: string;
  form_of_payment: string;
  signature_data: string;
  due_date?: string;
  discount: string;
  discount_start: string;
  discount_end: string;
  description?: string;
  automatic_blocking?: boolean;
}

const createClientFormSchema = yup.object().shape({
  office_branch_id: yup.string().required("Campo obrigatório."),
  contract_number: yup.string().required("Campo obrigatório."),
  form_of_payment: yup.string().required("Campo obrigatório."),
  signature_data: yup.string().required("Campo obrigatório."),
  due_date: yup.string(),
  discount: yup.string().required("Campo obrigatório."),
  discount_start: yup.string().required("Campo obrigatório."),
  discount_end: yup.string().required("Campo obrigatório."),
  description: yup.string(),
  automatic_blocking: yup.boolean(),
});

type Props = {
  callBackSuccess: (data: createClientFormData) => void;
  isBlock: (block: boolean) => void;
  typePayment: (type: string) => void;
};

export function FormDataClient({
  callBackSuccess,
  isBlock,
  typePayment,
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(createClientFormSchema) });

  const [companyData, setCompanyData] = useState<OptionsDTO[]>([]);
  const [clientName, setClientName] = useState("");
  const [loading, setLoading] = useState(true);

  async function getCompanyData() {
    try {
      setLoading(true);
      const response = await api.get("company/list");

      const data = response.data;

      const newDate: OptionsDTO[] = [];

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        newDate.push({
          label: element.company_name + " - " + element.cnpj,
          value: element.id,
        });
      }

      setCompanyData(newDate);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const handleCreate: SubmitHandler<createClientData> = async (value) => {
    let discount = value.discount.replace(".", "").replace(",", ".");
    callBackSuccess({
      office_branch_id: value.office_branch_id,
      contract_number: value.contract_number,
      form_of_payment: value.form_of_payment,
      signature_data: value.signature_data,
      due_date: value.due_date,
      discount: Number(discount),
      discount_start: value.discount_start,
      discount_end: value.discount_end,
      description: value.description,
      indefinite_time: false,
      automatic_blocking: value.automatic_blocking,
      is_blocked: false,
      credit_balance: 0,
      credit_amount_used: 0,
      credit_total_balance: 0,
      clent_name: clientName,
    });
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <Flex
      px={10}
      py={5}
      width="100%"
      flexDirection="column"
      as="form"
      onSubmit={handleSubmit(handleCreate)}
    >
      <Flex flex={1} alignItems="center" justifyContent="space-between">
        <Box p="4" bg="green.50" minHeight={340} borderRadius={8} width="879px">
          <Text fontWeight="bold" mb="4" color="green.800">
            INFORMAÇÕES DO CONTRATO CLIENTE:
          </Text>

          <Flex gap="2">
            <Box width="auto">
              <Controller
                control={control}
                name="office_branch_id"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    isLoading={loading}
                    width="410px"
                    label="CLIENTE:"
                    name="office_branch_id"
                    placeholder="Selecione o cliente"
                    options={companyData}
                    onChange={(item) => {
                      onChange(item.value);
                      setClientName(item.label);
                    }}
                    error={errors.office_branch_id}
                  />
                )}
              />
            </Box>

            <Controller
              control={control}
              name="contract_number"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  autoComplete="off"
                  width="210px"
                  name="contract_number"
                  label="N DO CONTRATO"
                  placeholder="Informe o numero do contrato"
                  value={value}
                  onChange={onChange}
                  error={errors.contract_number}
                />
              )}
            />

            <Controller
              control={control}
              name="due_date"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  type="date"
                  autoComplete="off"
                  width="210px"
                  name="due_date"
                  label="VENCIMENTO DO CONTRATO"
                  placeholder="Informe o vencimento do contrato"
                  value={value}
                  onChange={onChange}
                  error={errors.due_date}
                />
              )}
            />
          </Flex>

          <Flex gap="2" mt={5}>
            <Controller
              control={control}
              name="signature_data"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  type="date"
                  autoComplete="off"
                  width="200px"
                  name="signature_data"
                  label="DATA ASSINATURA"
                  placeholder="Informe a data da assinatura"
                  value={value}
                  onChange={onChange}
                  error={errors.signature_data}
                />
              )}
            />

            <Controller
              control={control}
              name="discount"
              render={({ field: { onChange, value } }) => (
                <InputPrice
                  width="200px"
                  name="discount"
                  label="% DE DESCONTO"
                  precision="2"
                  value={value}
                  onChange={onChange}
                  error={errors.discount}
                />
              )}
            />

            <Controller
              control={control}
              name="discount_start"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  type="date"
                  autoComplete="off"
                  width="210px"
                  name="discount_start"
                  label="INÍCIO DO DESCONTO"
                  placeholder="Informe o início do desconto"
                  value={value}
                  onChange={onChange}
                  error={errors.discount_start}
                />
              )}
            />

            <Controller
              control={control}
              name="discount_end"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  type="date"
                  autoComplete="off"
                  width="210px"
                  name="discount_end"
                  label="FIM DO DESCONTO"
                  placeholder="Informe o fim do desconto"
                  value={value}
                  onChange={onChange}
                  error={errors.discount_end}
                />
              )}
            />
          </Flex>

          <Flex gap="2" mt={5}>
            <Controller
              control={control}
              name="form_of_payment"
              render={({ field: { onChange, value } }) => (
                <InputSelect
                  width="410px"
                  label="FORMA DE PAGAMENTO:"
                  name="form_of_payment"
                  placeholder="Selecione a forma de pagamento"
                  options={[
                    {
                      label: "Crédito com pagamento antecipado",
                      value: "advance_credit",
                    },
                    {
                      label: "Pagamento postecipado semanal",
                      value: "weekly_payment",
                    },
                    {
                      label: "Pagamento postecipado quinzenal",
                      value: "biweekly_payment",
                    },
                    {
                      label: "Pagamento postecipado mensal",
                      value: "monthly_payment",
                    },
                    {
                      label: "Negociar com cliente",
                      value: "negotiate_with_client",
                    },
                  ]}
                  onChange={(item) => {
                    onChange(item.value);
                    typePayment(item.value);
                  }}
                  error={errors.form_of_payment}
                />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  autoComplete="off"
                  width="100%"
                  name="description"
                  label="OBSERVAÇÕES"
                  value={value}
                  onChange={onChange}
                  error={errors.description}
                />
              )}
            />
          </Flex>

          <Box mt={5}>
            <Controller
              control={control}
              name="automatic_blocking"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  size="lg"
                  colorScheme="green"
                  borderColor="gray.600"
                  isChecked={value}
                  onChange={(e) => {
                    isBlock(e.target.checked);
                    onChange(e.target.checked);
                  }}
                >
                  BLOQUEIO AUTOMÁTICO
                </Checkbox>
              )}
            />
          </Box>
        </Box>
      </Flex>

      <Button
        type="submit"
        bg="green.600"
        color="white"
        alignSelf="flex-end"
        height={10}
        _hover={{
          bg: "green.800",
        }}
        mt="6"
        size="lg"
      >
        Continuar
      </Button>
    </Flex>
  );
}
