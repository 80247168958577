import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../components/headerCard";

import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import {
  creditTypePayment,
  formatCurrency,
  formatedMonthDay,
} from "../../../utils/utils";
import { CreditClientDTO } from "../../../dtos/CreditClientDTO";
import { useParams } from "react-router-dom";
import { ContractDataDTO } from "../../../dtos/ContractDataDTO";
import { CardPrice } from "../../../components/cardPrice";
import {
  RiArrowDownCircleLine,
  RiArrowUpCircleLine,
  RiMoneyDollarCircleFill,
} from "react-icons/ri";
import { ModalCreateCredit } from "../modal/modal-create-credit";

export function ListCreditClient() {
  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [isModalTable, setIsModalTable] = useState(false);

  const [creditData, setCreditData] = useState<CreditClientDTO[]>([]);

  const [creditClient, setCreditClient] = useState({} as ContractDataDTO);

  async function getCredit() {
    try {
      setLoading(true);
      const response = await api.get(`contract/credit/list/${id}`);
      setCreditData(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function getCreditClient() {
    try {
      setLoading(true);
      const response = await api.get(`contract/show/${id}`);
      setCreditClient(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function addCredit() {
    setIsModalTable(false);
    getCreditClient();
    getCredit();
  }

  useEffect(() => {
    getCreditClient();
    getCredit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box>
      <HeaderCard
        title="HISTÓRICO DE CRÉDITO"
        titleButton="Nova crédio"
        isModal={true}
        onPressModal={() => {
          setIsModalTable(true);
        }}
        link=""
      />

      <ModalCreateCredit
        id={id ?? ""}
        is_open={isModalTable}
        onClose={() => {
          addCredit();
        }}
      />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {creditData.length > 0 && !loading && (
          <Box p="4" borderRadius={8} height={500}>
            <Flex mb={5} alignItems="center" gap={2}>
              <CardPrice
                icon={
                  <Icon
                    fontSize="25px"
                    color="green.500"
                    as={RiArrowUpCircleLine}
                  />
                }
                title="Entradas"
                price={creditClient.credit_balance ?? 0}
              />

              <CardPrice
                icon={
                  <Icon
                    fontSize="25px"
                    color="red.500"
                    as={RiArrowDownCircleLine}
                  />
                }
                title="Saídas"
                price={creditClient.credit_amount_used ?? 0}
              />

              <CardPrice
                icon={
                  <Icon
                    fontSize="25px"
                    color="white"
                    as={RiMoneyDollarCircleFill}
                  />
                }
                title="Total"
                price={creditClient.credit_total_balance ?? 0}
                isTotal
              />
            </Flex>

            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      DATA
                    </Th>
                    <Th color="white" textAlign="center">
                      VALOR DO CRÉDITO
                    </Th>
                    <Th color="white" textAlign="center">
                      TIPO DE PAGAMENTO
                    </Th>
                    <Th color="white" textAlign="center">
                      N DO DOCUMENTO
                    </Th>
                    <Th color="white" textAlign="center">
                      N DA AGÊNCIA
                    </Th>
                    <Th color="white" textAlign="center">
                      N DA CONTA
                    </Th>
                    <Th color="white" textAlign="center">
                      BANCO
                    </Th>
                    <Th color="white" textAlign="center">
                      OBSERVAÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {creditData.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">
                        {formatedMonthDay(item.credit_date)}
                      </Td>
                      <Td textAlign="center">
                        R$ {formatCurrency(item.credit_amount)}
                      </Td>
                      <Td textAlign="center">
                        {creditTypePayment(item.form_of_payment)}
                      </Td>
                      <Td textAlign="center">{item.document_number}</Td>
                      <Td textAlign="center">{item.agency_number}</Td>
                      <Td textAlign="center">{item.account_number}</Td>
                      <Td textAlign="center">{item.name_bank}</Td>
                      <Td textAlign="center">
                        {formatedMonthDay(item.comments)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
}
