import { extendTheme } from "@chakra-ui/react";

export const themeChakra = extendTheme({
  colors: {
    gray: {
      "50": "#F8FAFC",
      "400": "#E2E8F0",
      "600": "#475569",
      "800": "#1E293B",
    },
    green: {
      "50": "#EBEEF4",
      "400": "#69B0B9",
      "600": "#347885",
      "800": "#002D36",
    },
  },
  fonts: {
    heading: "Titillium Web",
    body: "Titillium Web",
  },
  styles: {
    global: {
      body: {
        bg: "gray.50",
      },
    },
  },
});
