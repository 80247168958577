import axios from "axios";
import { Cookies } from "react-cookie-consent";

const token = Cookies.get("etankprovider.token");

export const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
