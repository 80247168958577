import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ProductUpdateItemDTO } from "../../../dtos/ProductUpdateItemDTO";
import { api } from "../../../service/api";
import {
  formatCurrency,
  formatedMonthDay,
  typeUpdatePrice,
} from "../../../utils/utils";

type Props = {
  update_product_id: string;
  is_open: boolean;
  onClose: () => void;
};
export function ModalProductUpdate({
  update_product_id,
  is_open,
  onClose,
  ...rest
}: Props) {
  const [products, setProducts] = useState<ProductUpdateItemDTO[]>([]);
  const [loading, setLoading] = useState(true);

  async function getProducts() {
    try {
      setLoading(true);
      const response = await api.get(
        `product/update/list/item/${update_product_id}`
      );
      setProducts(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (is_open && update_product_id) {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update_product_id, is_open]);

  return (
    <>
      <Modal isOpen={is_open} onClose={onClose} isCentered {...rest} size="5xl">
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader>Produtos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={50}
                  w={50}
                />
              </Box>
            )}
            {!loading && (
              <TableContainer>
                <Table variant="striped" colorScheme="gray" size="sm">
                  <Thead>
                    <Tr bg="green.600" height="10">
                      <Th color="white">NOME COMERCIAL</Th>
                      <Th color="white" textAlign="center">
                        UND
                      </Th>
                      <Th color="white" textAlign="center">
                        QUANTIDADE
                      </Th>
                      <Th color="white">PREÇO ATUAL</Th>
                      <Th color="white">DATA</Th>
                      <Th color="white">TIPO</Th>
                      <Th color="white">INDICE</Th>
                      <Th color="white">NOVO PREÇO</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {products.map((item, index) => (
                      <Tr key={index}>
                        <Td textAlign="center">
                          {item.product_furnisher.name}
                        </Td>
                        <Td textAlign="center">
                          {item.product_furnisher.unit}
                        </Td>
                        <Td textAlign="center">
                          {formatCurrency(item.product_furnisher.quantity)}
                        </Td>
                        <Td textAlign="center">
                          {formatCurrency(item.previous_price)}
                        </Td>
                        <Td textAlign="center">
                          {formatedMonthDay(item.data_update)}
                        </Td>
                        <Td textAlign="center">
                          {typeUpdatePrice(item.type_update)}
                        </Td>
                        <Td textAlign="center">
                          {formatCurrency(item.indication)}%
                        </Td>
                        <Td textAlign="center">
                          {formatCurrency(item.price_adjustment)}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
