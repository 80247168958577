import { Box, Flex, Text } from "@chakra-ui/react";
import { formatCurrency } from "../../utils/utils";

type Props = {
  isTotal?: boolean;
  title: string;
  price: number;
  icon: any;
};

export function CardPrice({ title, price, icon, isTotal = false }: Props) {
  return (
    <Box
      width="352px"
      height="100px"
      borderRadius="6px"
      bg={isTotal ? "green.600" : "gray.400"}
      px={5}
      py={4}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text color={isTotal ? "white" : "gray.600"} fontSize="md">
          {title}
        </Text>
        {icon}
      </Flex>

      <Text
        fontWeight="bold"
        color={isTotal ? "white" : "gray.800"}
        mt={2}
        fontSize="26px"
      >
        R$ {formatCurrency(price)}
      </Text>
    </Box>
  );
}
