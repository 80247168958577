import { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import { useSearchParams } from "react-router-dom";
import { api } from "../../../service/api";
import { formatCurrency } from "../../../utils/utils";

interface IFormatDataChart {
  series: IDataChart[];
}

interface IDataChart {
  name: string;
  data: Array<number>;
}

export function useCostGeneral() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<IFormatDataChart>(
    {} as IFormatDataChart
  );

  const [categories, setCategories] = useState<ApexOptions>();

  const [searchParams] = useSearchParams();

  const company_id = searchParams.get("company_id") ?? "";
  const product_id = searchParams.get("product_id") ?? "";
  const start_date = searchParams.get("start_date") ?? "";
  const end_date = searchParams.get("end_date") ?? "";

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `dash/requisition/cost/list?company_id=${company_id}&product_id=${product_id}&start_date=${start_date}&end_date=${end_date}`
      );
      const dataValue = response.data;
      const data: IFormatDataChart = getFormatDataChart(dataValue.values);
      setDataList(data);

      setCategories(getOptions(dataValue.categories));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id, product_id, start_date, end_date]);

  return {
    dataList,
    categories,
    loading,
    error,
    refetch: fetchData,
  };
}

function getFormatDataChart(iFormatDataChart: IFormatDataChart) {
  return iFormatDataChart;
}

function getOptions(categories: Array<string>) {
  const options: ApexOptions = {
    chart: {
      height: 200,
      type: "bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#69B0B9"],
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return "R$ " + (formatCurrency(Number(val)) as string) ?? "0";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories,
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return "R$ " + (formatCurrency(val) as string) ?? "0";
        },
      },
    },
  };
  return options;
}
