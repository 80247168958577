import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import LogoEtank from "../../../assets/Logo-e-tank-grande.png";
import { Input } from "../../../components/form/input";
import { InputPassword } from "../../../components/form/inputPassword";
import { InputMask } from "../../../components/form/inputMask";
import { AllLoading } from "../../../components/allLoading";
import { useState } from "react";
import { CnpjDataDTO } from "../../../dtos/CnpjDataDTO";
import { api } from "../../../service/api";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { ModalContract } from "./modal/modal-contract";

type createFormData = {
  name: string;
  email: string;
  email_furnisher: string;
  company_name: string;
  responsible: string;
  password: string;
  cellphone: string;
  cnpj: string;
  company_number: string;
  fantasy_name?: string;
  city: string;
  uf: string;
  place: string;
  cep: string;
  complement?: string;
  term?: boolean;
  fuels?: boolean;
  lubricants?: boolean;
  parts?: boolean;
  services?: boolean;
};

const createFormDataSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  company_name: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  email_furnisher: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  responsible: yup.string().required("Campo obrigatório."),
  password: yup.string().required("Campo obrigatório."),
  cellphone: yup.string().required("Campo obrigatório."),
  cnpj: yup.string().required("Campo obrigatório."),
  company_number: yup.string().required("Campo obrigatório."),
  fantasy_name: yup.string(),
  cep: yup.string().required("Campo obrigatório."),
  city: yup.string().required("Campo obrigatório."),
  uf: yup.string().required("Campo obrigatório."),
  place: yup.string().required("Campo obrigatório."),
  complement: yup.string(),
  term: yup.boolean(),
  fuels: yup.boolean(),
  lubricants: yup.boolean(),
  parts: yup.boolean(),
  services: yup.boolean(),
});

export function RegisterAccount() {
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [isOpenModalContract, setIsOpenModalContract] = useState(false);

  const { isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  if (isAtuhLoading) return <Box />;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  async function getCompanyCnpj(cnpj: string) {
    try {
      setLoading(true);
      const response = await api.post(`cnpj/index`, {
        cnpj,
      });
      const data: CnpjDataDTO = response.data;
      setValue("company_name", data.company_name);
      setValue("fantasy_name", data.fantasy_name);
      setValue("cnpj", data.cnpj);
      setValue("city", data.city);
      setValue("uf", data.state);
      setValue("place", data.public_place);
      setValue("cep", data.cep);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      if (!value.term) {
        toast({
          position: "top-right",
          title: "Criar conta",
          description: "Para continuar é necessário aceitar os termos.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      if (
        !value.fuels &&
        !value.lubricants &&
        !value.parts &&
        !value.services
      ) {
        toast({
          position: "top-right",
          title: "Criar conta",
          description:
            "Para continuar é necessário selecionar um dos itens ofertados.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      await api.post("account/create", value);

      navigate("/cadastro/sucesso");

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Conta cadastrada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível criar conta.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      w="100vw"
      h="120vh"
      justifyContent="center"
      alignItems="center"
      px={40}
      py={20}
      as="form"
      onSubmit={handleSubmit(handleSend)}
    >
      {(loading || isSubmitting) && <AllLoading />}

      <ModalContract
        is_open={isOpenModalContract}
        onClose={() => {
          setIsOpenModalContract(false);
        }}
      />

      <Stack flex={1} px={100} spacing={1}>
        <Flex gap={2}>
          <InputMask
            size="md"
            label="CNPJ:"
            placeholder="Informe o CNPJ"
            mask="99.999.999/9999-99"
            {...register("cnpj")}
            onChange={(e) => {
              let cnpj = e.target.value;
              if (cnpj.length === 18) {
                getCompanyCnpj(cnpj);
              }
            }}
            error={errors.cnpj}
          />

          <Input
            size="md"
            label="Responsável:"
            placeholder="Informe o responsável"
            {...register("responsible")}
            error={errors.responsible}
          />
        </Flex>
        <Input
          size="md"
          {...register("company_name")}
          error={errors.company_name}
          label="Razão social:"
          placeholder="Informe a razão social"
        />
        <Input
          size="md"
          {...register("fantasy_name")}
          error={errors.fantasy_name}
          label="Nome fantasia:"
          placeholder="Informe o nome fantasia"
        />
        <Flex gap={2}>
          <Input
            size="md"
            type="email"
            label="Email da empresa:"
            placeholder="Informe o email da empresa"
            {...register("email_furnisher")}
            error={errors.email_furnisher}
          />
          <InputMask
            size="md"
            label="Celular:"
            placeholder="Informe o celular"
            mask="(99)99999-99999"
            {...register("cellphone")}
            error={errors.cellphone}
          />
        </Flex>
        <Flex gap={2}>
          <InputMask
            size="md"
            label="CEP:"
            placeholder="Informe o CEP"
            width="376px"
            mask="99999-999"
            {...register("cep")}
          />
          <Input
            size="md"
            label="Município:"
            placeholder="Informe o Município"
            width="265px"
            {...register("city")}
          />
          <Input
            size="md"
            label="UF:"
            placeholder="UF"
            width="100px"
            textAlign="center"
            {...register("uf")}
          />
        </Flex>

        <Input
          size="md"
          label="Logradouro:"
          placeholder="Informe o Logradouro"
          {...register("place")}
        />

        <Flex gap={2}>
          <Input
            size="md"
            label="Número:"
            placeholder="Informe o número"
            {...register("company_number")}
            error={errors.company_number}
          />
          <Input
            size="md"
            label="Complemento:"
            placeholder="Informe o complemento"
            {...register("complement")}
          />
        </Flex>

        <Input
          size="md"
          label="Nome do usuário:"
          placeholder="Informe o nome do usuário"
          {...register("name")}
          error={errors.name}
        />

        <Flex gap={2}>
          <Input
            size="md"
            label="Email do usuário:"
            type="email"
            placeholder="Informe o email do usuário"
            {...register("email")}
            error={errors.email}
          />

          <InputPassword
            size="md"
            type="password"
            label="Senha:"
            placeholder="Informe a senha"
            {...register("password")}
            error={errors.password}
          />
        </Flex>
      </Stack>

      <Flex alignItems="center" flexDirection="column" width="400px">
        <Image src={LogoEtank} width={200} alt="Etank" />

        <Text fontWeight="bold" mt={5} fontSize="36px" color="gray.800">
          CRIAR CONTA
        </Text>

        <Text color="gray.600" textAlign="center" maxWidth="400px">
          Crie sua conta para fornecer produtos e serviços para os parceiros
          E-tank
        </Text>

        <Box mt={10}>
          <Text fontWeight="bold" fontSize="20px" color="gray.600">
            Itens ofertados:
          </Text>
          <Flex mt={2} gap={2}>
            <Checkbox
              size="lg"
              {...register("fuels")}
              colorScheme="green"
              borderColor="gray.600"
            >
              Combustíveis
            </Checkbox>
            <Checkbox
              size="lg"
              colorScheme="green"
              {...register("lubricants")}
              borderColor="gray.600"
            >
              Lubrificantes
            </Checkbox>
            <Checkbox
              size="lg"
              colorScheme="green"
              {...register("parts")}
              borderColor="gray.600"
            >
              Peças
            </Checkbox>
            <Checkbox
              size="lg"
              colorScheme="green"
              {...register("services")}
              borderColor="gray.600"
            >
              Serviços
            </Checkbox>
          </Flex>
        </Box>

        <Box mt={10}>
          <Button
            type="submit"
            bg="green.600"
            color="white"
            _hover={{
              bg: "green.800",
            }}
            size="lg"
            width="100%"
          >
            CRIAR CONTA
          </Button>

          <Flex mt={5} alignItems="center">
            <Checkbox
              size="md"
              colorScheme="green"
              {...register("term")}
              borderColor="gray.600"
            >
              Declaro que li e concordo integralmente com todos os termos do
              contrato.
            </Checkbox>

            <Button
              size="sm"
              width="150px"
              onClick={() => {
                setIsOpenModalContract(true);
              }}
            >
              Ver contrato
            </Button>
          </Flex>
          {!!errors && <Text>{errors.term?.message}</Text>}
        </Box>
      </Flex>
    </Flex>
  );
}
