import { Box, Flex, useToast } from "@chakra-ui/react";
import { HeaderCard } from "../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { MultiStep } from "../../../components/multiStep";
import { ProductInfo, ProductsInfo } from "./step/productInfo";
import { FormInfo, createUpdatePriceData } from "./step/form-info";
import { useState } from "react";
import { AllLoading } from "../../../components/allLoading";
import { useNavigate } from "react-router-dom";
import { api } from "../../../service/api";

export function CreateUpdatePrice() {
  const toast = useToast();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const [loading, setLoading] = useState(false);

  const [dataForm, setDataForm] = useState<createUpdatePriceData>(
    {} as createUpdatePriceData
  );

  async function sendUpdateProducts(data: ProductsInfo[]) {
    try {
      setLoading(true);

      await api.put("/product/update", {
        ...dataForm,
        productsPriceItems: data,
      });

      toast({
        position: "top-right",
        title: "Produto",
        description: "Produto atualizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/atualizar-precos");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Não foi possível atualizar preço dos produtos.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <HeaderCard
        title="ATUALIZAÇÃO DE PREÇOS"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/atualizar-precos"
      />
      {loading && <AllLoading />}

      <Flex justifyContent="center">
        <Box>
          <Box mt={5} ml={10} width={220}>
            <MultiStep size={2} currentStep={step} />
          </Box>
          {step === 1 && (
            <FormInfo
              callBackSuccess={(data) => {
                setDataForm(data);
                setStep(2);
              }}
            />
          )}

          {step === 2 && (
            <ProductInfo
              indice={dataForm.indication}
              date={dataForm.data_update}
              type={dataForm.type_update}
              callBackSuccess={(data) => {
                sendUpdateProducts(data);
              }}
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
}
