import {
  // Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  // HStack,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { RiNotificationLine } from "react-icons/ri";

export function DrawerNotification() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    onOpen();
  };

  return (
    <>
      <Button
        onClick={() => handleClick()}
        bg="transparent"
        border="none"
        _hover={{
          bg: "green.400",
        }}
      >
        <Icon color="white" fontSize={25} as={RiNotificationLine} />
      </Button>

      <Drawer onClose={onClose} isOpen={isOpen} size={"xs"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Central de notificações</DrawerHeader>
          <DrawerBody>
            {/* <Box
              width="100%"
              borderRadius={5}
              bg="green.400"
              transition="all 0.5s"
              _hover={{ bg: "green.600", cursor: "pointer" }}
              mb={1}
              onClick={() => {
                alert("Notificacoes");
              }}
            >
              <HStack alignItems="center" height="70px" px={2}>
                <Box width="30px" textAlign="center">
                  <Icon color="green.800" as={RiNotificationLine} />
                </Box>
                <Text>
                  Lorem Ipsum is simply dummy text of the printing and
                </Text>
              </HStack>
            </Box> */}

            <Text textAlign="center">Sem notificações.</Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
