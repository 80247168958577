import {
  Box,
  Button,
  Checkbox,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { ProductDTO } from "../../../../dtos/productDTO";
import { api } from "../../../../service/api";
import { useEffect, useState } from "react";
import {
  formatCurrency,
  formatedMonthDay,
  typeUpdatePrice,
} from "../../../../utils/utils";

export interface ProductsInfo {
  id: number;
  product_furnisher_id: string;
  is_check: boolean;
  product_name: string;
  unit: string;
  quantity: string;
  previous_price: number;
  type_update: string;
  indication: number;
  data_update: string;
  price_adjustment: number;
}

type Props = {
  indice: number;
  date: string;
  type: string;
  callBackSuccess: (data: ProductsInfo[]) => void;
};

export function ProductInfo({ indice, date, type, callBackSuccess }: Props) {
  const toast = useToast();

  const [newProducts, setNewProducts] = useState<ProductsInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [allChecked, setAllChecked] = useState(false);

  async function getProducts(indice: number, date: string, type: string) {
    try {
      setLoading(true);
      const response = await api.get("product/listAll");
      updateInfoProducts(response.data, indice, date, type);
    } catch (error) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Não foi possível carregar os produtos.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  function updateInfoProducts(
    producs: ProductDTO[],
    indice: number,
    date: string,
    type: string
  ) {
    const ProductsInfo: ProductsInfo[] = [];

    for (let index = 0; index < producs.length; index++) {
      const product = producs[index];

      let calcIndice = type === "accrual" ? indice / 100 : (indice / 100) * -1;
      let newPrice = product.price * (1 + calcIndice);

      ProductsInfo.push({
        product_furnisher_id: product.id,
        id: index,
        is_check: false,
        product_name: product.name,
        quantity: product.quantity,
        previous_price: product.price,
        unit: product.unit,
        data_update: date,
        indication: indice,
        price_adjustment: newPrice,
        type_update: type,
      });
    }

    setNewProducts(ProductsInfo);
  }

  function updatelChecked(
    is_all: boolean,
    is_check: boolean,
    item?: ProductsInfo
  ) {
    const ProductsInfo: ProductsInfo[] = [];

    for (let index = 0; index < newProducts.length; index++) {
      const product = newProducts[index];

      ProductsInfo.push({
        product_furnisher_id: product.product_furnisher_id,
        id: product.id,
        is_check: is_all
          ? is_check
          : product.id === item?.id
          ? is_check
          : product.is_check,
        product_name: product.product_name,
        quantity: product.quantity,
        previous_price: product.previous_price,
        unit: product.unit,
        data_update: product.data_update,
        indication: product.indication,
        price_adjustment: product.price_adjustment,
        type_update: product.type_update,
      });
    }

    setNewProducts(ProductsInfo);
  }

  function sendTablePrice() {
    const isSelectedData = newProducts.filter((e) => e.is_check === true);
    if (isSelectedData.length === 0) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Selecione um produto para continuar",
        status: "info",
        duration: 9000,
        isClosable: true,
      });

      return;
    }

    callBackSuccess(isSelectedData);
  }

  useEffect(() => {
    getProducts(indice, date, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indice, date, type]);

  useEffect(() => {
    const isAllCheck = newProducts.find((e) => e.is_check === false);
    setAllChecked(!isAllCheck);
  }, [newProducts]);

  return (
    <Flex flexDirection="column" width="1000px">
      <Box px={10} py={5} borderRadius={8} height={400}>
        {loading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={400}
          >
            <Spinner
              color="green.600"
              emptyColor="white"
              thickness="4px"
              size="xl"
              h={100}
              w={100}
            />
          </Box>
        )}
        {!loading && (
          <TableContainer>
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr bg="green.600" height="10">
                  <th>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="40px"
                    >
                      <Checkbox
                        size="lg"
                        colorScheme="green"
                        isChecked={allChecked}
                        onChange={(e) => {
                          updatelChecked(true, e.target.checked);
                          setAllChecked(e.target.checked);
                        }}
                      />
                    </Box>
                  </th>
                  <Th color="white">NOME COMERCIAL</Th>
                  <Th color="white" textAlign="center">
                    UND
                  </Th>
                  <Th color="white" textAlign="center">
                    QUANTIDADE
                  </Th>
                  <Th color="white">PREÇO ATUAL</Th>
                  <Th color="white">DATA</Th>
                  <Th color="white">TIPO</Th>
                  <Th color="white">INDICE</Th>
                  <Th color="white">NOVO PREÇO</Th>
                </Tr>
              </Thead>
              <Tbody>
                {newProducts.map((item, index) => (
                  <Tr key={index}>
                    <td>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="40px"
                      >
                        <Checkbox
                          size="lg"
                          colorScheme="green"
                          isChecked={item.is_check}
                          onChange={(e) => {
                            updatelChecked(false, e.target.checked, item);
                          }}
                        />
                      </Box>
                    </td>
                    <Td>{item.product_name}</Td>
                    <Td textAlign="center">{item.unit}</Td>
                    <Td textAlign="center">
                      {formatCurrency(Number(item.quantity))}
                    </Td>
                    <Td textAlign="center">
                      R$ {formatCurrency(item.previous_price)}
                    </Td>
                    <Td textAlign="center">
                      {formatedMonthDay(item.data_update)}
                    </Td>
                    <Td textAlign="center">
                      {typeUpdatePrice(item.type_update)}
                    </Td>
                    <Td textAlign="center">
                      {formatCurrency(item.indication)}%
                    </Td>
                    <Td textAlign="center">
                      R$ {formatCurrency(item.price_adjustment)}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {!loading && (
        <Box px={10} alignSelf="flex-end">
          <Button
            type="submit"
            bg="green.600"
            color="white"
            alignSelf="flex-end"
            height={10}
            _hover={{
              bg: "green.800",
            }}
            mt="6"
            size="lg"
            onClick={sendTablePrice}
          >
            Finalizar
          </Button>
        </Box>
      )}
    </Flex>
  );
}
