import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  clientTypePayment,
  creditTypePayment,
  formatCurrency,
  formatedMonthDay,
} from "../../../../utils/utils";
import { ProductsInfo } from "./form-table-products";
import { createClientFormData } from "./form-data-client";
import { createCreditFormData } from "./form-credit-client";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { api } from "../../../../service/api";

type Props = {
  clientData: createClientFormData;
  creditData: createCreditFormData;
  products: ProductsInfo[];
};

export function ResumeClient({ products, clientData, creditData }: Props) {
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef = React.useRef<any>(null);

  function cancelCreateClient() {
    navigate("/clientes");
  }

  async function sendClient() {
    try {
      setLoading(true);

      if (clientData.form_of_payment === "advance_credit") {
        clientData.credit_amount_used = 0;
        clientData.credit_balance = Number(creditData.credit_amount);
        clientData.credit_total_balance = Number(creditData.credit_amount);
      }

      await api.post("/contract/create", {
        ...clientData,
        credit_history:
          clientData.form_of_payment === "advance_credit" ? creditData : null,
        products_office: products,
      });

      toast({
        position: "top-right",
        title: "Produto",
        description: "Cliente cadastrado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/clientes");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Não foi possível cadastrar o cliente.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  function cardInfo(title: string, descrition: string) {
    return (
      <Box>
        <Heading size="xs" textTransform="uppercase" color="gray.800">
          {title}
        </Heading>
        <Text pt="1" textAlign="center" fontSize="sm" color="gray.600">
          {descrition}
        </Text>
      </Box>
    );
  }

  return (
    <Flex px={10} py={5} width="100%" flexDirection="column">
      <Flex flex={1} alignItems="center" justifyContent="space-between">
        <Box minHeight={300} borderRadius={8} width="879px">
          <Card>
            <CardHeader>
              <Heading size="md">INOFORMAÇÕES DO CLIENTE:</Heading>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box display="flex" gap={4}>
                  {cardInfo("CLIENTE:", clientData.clent_name)}
                  {cardInfo("N DO CONTRATO:", clientData.contract_number)}
                  {cardInfo(
                    "VENCIMENTO DO CONTRATO:",
                    formatedMonthDay(clientData.due_date ?? "")
                  )}
                  {cardInfo(
                    "DATA ASSINATURA:",
                    formatedMonthDay(clientData.signature_data)
                  )}
                </Box>

                <Box display="flex" gap={4}>
                  {cardInfo(
                    "% DE DESCONTO:",
                    formatCurrency(clientData.discount).toString()
                  )}
                  {cardInfo(
                    "INÍCIO DO DESCONTO:",
                    formatedMonthDay(clientData.discount_start)
                  )}
                  {cardInfo(
                    "FIM DO DESCONTO:",
                    formatedMonthDay(clientData.discount_end)
                  )}
                  {cardInfo(
                    "FORMA DE PAGAMENTO:",
                    clientTypePayment(clientData.form_of_payment)
                  )}
                </Box>
                <Box display="flex" gap={4}>
                  {cardInfo("OBSERVAÇÕES:", clientData.description ?? "")}
                </Box>
              </Stack>
            </CardBody>
          </Card>

          {creditData.credit_amount && (
            <Card mt={5}>
              <CardHeader>
                <Heading size="md">INOFORMAÇÕES DO CRÉDITO:</Heading>
              </CardHeader>

              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Box display="flex" gap={4}>
                    {cardInfo(
                      "DATA:",
                      formatedMonthDay(creditData.credit_date)
                    )}
                    {cardInfo(
                      "VALOR DO CRÉDITO:",
                      formatCurrency(
                        Number(creditData.credit_amount)
                      ).toString()
                    )}
                    {cardInfo(
                      "FORMA DE PAGAMENTO:",
                      creditTypePayment(creditData.form_of_payment)
                    )}
                    {cardInfo("N DOCUMENTO:", creditData.document_number ?? "")}
                  </Box>

                  <Box display="flex" gap={4}>
                    {cardInfo("N DA AGÊNCIA:", creditData.agency_number ?? "")}
                    {cardInfo("N DA CONTA:", creditData.account_number ?? "")}
                    {cardInfo("BANCO:", creditData.name_bank ?? "")}
                  </Box>
                  <Box display="flex" gap={4}>
                    {cardInfo("OBSERVAÇÕES:", creditData.comments ?? "")}
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          )}

          <Card mt={5}>
            <CardHeader>
              <Heading size="md">INOFORMAÇÕES DO PRODUTO:</Heading>
            </CardHeader>

            <CardBody>
              <TableContainer>
                <Table variant="striped" colorScheme="gray" size="sm">
                  <Thead>
                    <Tr bg="green.600" height="10">
                      <Th color="white">NOME COMERCIAL</Th>
                      <Th color="white" textAlign="center">
                        UND
                      </Th>
                      <Th color="white" textAlign="center">
                        QUANTIDADE
                      </Th>
                      <Th color="white" textAlign="center">
                        PREÇO ATUAL
                      </Th>
                      <Th color="white" textAlign="center">
                        % DE DESCONTO
                      </Th>
                      <Th color="white" textAlign="center">
                        NOVO PREÇO
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {products.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.product_name}</Td>

                        <Td textAlign="center">{item.unit}</Td>
                        <Td textAlign="center">
                          {formatCurrency(Number(item.quantity))}
                        </Td>
                        <Td textAlign="center">
                          R$ {formatCurrency(item.price)}
                        </Td>
                        <Td textAlign="center">
                          {formatCurrency(item.discount)}%
                        </Td>
                        <Td textAlign="center">
                          R$ {formatCurrency(item.new_price)}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>

          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
          >
            <Button
              type="submit"
              height={10}
              colorScheme="red"
              mt="6"
              size="lg"
              onClick={() => {
                onOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              bg="green.600"
              color="white"
              alignSelf="flex-end"
              height={10}
              _hover={{
                bg: "green.800",
              }}
              mt="6"
              size="lg"
              isLoading={loading}
              onClick={() => {
                sendClient();
              }}
            >
              Finalizar
            </Button>
          </Box>
        </Box>
      </Flex>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cadastro do cliente
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Deseja cancelar o cadastro do cliente</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    cancelCreateClient();
                  }}
                >
                  Sim
                </Button>
                <Button ml={3} onClick={onClose}>
                  Nāo
                </Button>
              </>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}
