import { Box, Button, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { HeaderCard } from "../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Input } from "../../../components/form/input";
import { InputPassword } from "../../../components/form/inputPassword";
import { InputSelectBox } from "../../../components/form/inputSelectBox";
import { InputMask } from "../../../components/form/inputMask";
import { api } from "../../../service/api";
import { useNavigate } from "react-router-dom";

type createFormData = {
  name: string;
  email: string;
  password: string;
  re_password: string;
  cellphone: string;
  status?: string;
  is_app?: boolean;
  is_panel?: boolean;
};

const createFormDataSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  password: yup.string().required("Campo obrigatório."),
  re_password: yup
    .string()
    .required("Confirme a senha")
    .oneOf([yup.ref("password"), ""], "Confirme a senha"),
  cellphone: yup.string().required("Campo obrigatório."),
  status: yup.string(),
  is_app: yup.boolean(),
  is_panel: yup.boolean(),
});

export function CreateUser() {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      value.is_app = value.is_app ? true : false;
      value.is_panel = value.is_panel ? true : false;

      await api.post("admin/user/create", {
        ...value,
        status: "active",
      });

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/usuarios");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível cadastrar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <HeaderCard
        title="CADASTRO DE USUARIO"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/usuarios"
      />

      <Box
        display="flex"
        justifyContent="center"
        bg="gray.50"
        minH="100%"
        maxH="100%"
        pb={20}
        pt={10}
      >
        <Box
          width="800px"
          display="flex"
          flexDirection="column"
          as="form"
          onSubmit={handleSubmit(handleSend)}
        >
          <Stack mt={5} spacing={4}>
            <Input
              size="md"
              label="Nome:"
              placeholder="Informe o nome"
              {...register("name")}
              error={errors.name}
            />
            <Input
              size="md"
              label="Email:"
              type="email"
              placeholder="Informe o email"
              {...register("email")}
              error={errors.email}
            />

            <Flex gap={2}>
              <InputPassword
                type="password"
                size="md"
                label="Senha:"
                placeholder="Informe a senha"
                {...register("password")}
                error={errors.password}
              />
              <InputPassword
                type="password"
                size="md"
                label="Confirmar senha:"
                placeholder="Confirme sua senha"
                {...register("re_password")}
                error={errors.re_password}
              />
            </Flex>

            <Flex gap={2} display="none">
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => (
                  <InputSelectBox
                    label="Status:"
                    name="status"
                    options={[
                      {
                        label: "Ativo",
                        value: "active",
                      },
                      {
                        label: "Desativado",
                        value: "disabled",
                      },
                    ]}
                    width="100%"
                    placeholder="Selecione o cliente"
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                  />
                )}
              />

              <InputMask
                size="md"
                label="Celular:"
                placeholder="Informe o celular"
                mask="(99)99999-99999"
                {...register("cellphone")}
                error={errors.cellphone}
              />
            </Flex>

            <Box mt={3} display="flex">
              <Controller
                control={control}
                name="is_app"
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    size="lg"
                    colorScheme="green"
                    borderColor="gray.600"
                    isChecked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                  >
                    ACESSO AO APP
                  </Checkbox>
                )}
              />

              <Controller
                control={control}
                name="is_panel"
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    ml={3}
                    size="lg"
                    colorScheme="green"
                    borderColor="gray.600"
                    isChecked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                  >
                    ACESSO AO PAINEL
                  </Checkbox>
                )}
              />
            </Box>
          </Stack>

          <Button
            type="submit"
            bg="green.600"
            color="white"
            _hover={{
              bg: "green.800",
            }}
            mt="6"
            size="lg"
            alignSelf="flex-end"
            isLoading={isSubmitting}
          >
            Cadastrar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
