import { Box, Button, Flex, Text } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { InputLine } from "../../../../components/form/InputLine";
import { InputPrice } from "../../../../components/form/inputPrice";
import { InputSelect } from "../../../../components/form/inputSelect";

export type createCreditFormData = {
  credit_date: string;
  credit_amount: string;
  form_of_payment: string;
  document_number?: string;
  agency_number?: string;
  account_number?: string;
  name_bank?: string;
  comments?: string;
};

const createCreditFormSchema = yup.object().shape({
  credit_date: yup.string().required("Campo obrigatório."),
  credit_amount: yup.string().required("Campo obrigatório."),
  form_of_payment: yup.string().required("Campo obrigatório."),
  document_number: yup.string(),
  agency_number: yup.string(),
  account_number: yup.string(),
  name_bank: yup.string(),
  comments: yup.string(),
});

type Props = {
  callBackSuccess: (data: createCreditFormData) => void;
  isModal?: boolean;
  loading?: boolean;
};

export function FormCreditClient({
  callBackSuccess,
  isModal = false,
  loading = false,
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(createCreditFormSchema) });

  const handleCreate: SubmitHandler<createCreditFormData> = async (value) => {
    const credit_amount = value.credit_amount
      .replace(".", "")
      .replace(",", ".");
    value.credit_amount = credit_amount;
    callBackSuccess(value);
  };

  return (
    <Flex
      px={10}
      py={5}
      width="100%"
      flexDirection="column"
      as="form"
      onSubmit={handleSubmit(handleCreate)}
    >
      <Flex flex={1} alignItems="center" justifyContent="space-between">
        <Box p="4" bg="green.50" minHeight={300} borderRadius={8} width="879px">
          <Text fontWeight="bold" mb="4" color="green.800">
            CRÉDITO ANTECIPADO:
          </Text>

          <Flex gap="2">
            <Controller
              control={control}
              name="credit_date"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  type="date"
                  autoComplete="off"
                  width="100%"
                  name="credit_date"
                  label="DATA"
                  placeholder="Informe a data"
                  value={value}
                  onChange={onChange}
                  error={errors.credit_date}
                />
              )}
            />

            <Controller
              control={control}
              name="credit_amount"
              render={({ field: { onChange, value } }) => (
                <InputPrice
                  width="100%"
                  name="credit_amount"
                  label="VALOR DO CRÉDITO"
                  precision="2"
                  value={value}
                  onChange={onChange}
                  error={errors.credit_amount}
                />
              )}
            />

            <Box width="100%">
              <Controller
                control={control}
                name="form_of_payment"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    width="100%"
                    label="FORMA DE PAGAMENTO:"
                    name="form_of_payment"
                    placeholder="Selecione forma ..."
                    options={[
                      {
                        label: "Dinheiro",
                        value: "money",
                      },
                      {
                        label: "Depósito",
                        value: "deposit",
                      },
                      {
                        label: "Transferência",
                        value: "transfer",
                      },
                    ]}
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                    error={errors.form_of_payment}
                  />
                )}
              />
            </Box>

            <Controller
              control={control}
              name="document_number"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  autoComplete="off"
                  width="100%"
                  name="document_number"
                  label="N DOCUMENTO"
                  placeholder="Informe o número documento"
                  value={value}
                  onChange={onChange}
                  error={errors.document_number}
                />
              )}
            />
          </Flex>

          <Flex gap="2" mt={5}>
            <Controller
              control={control}
              name="agency_number"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  autoComplete="off"
                  width="100%"
                  name="agency_number"
                  label="N DA AGÊNCIA"
                  placeholder="Informe a agência"
                  value={value}
                  onChange={onChange}
                  error={errors.agency_number}
                />
              )}
            />
            <Controller
              control={control}
              name="account_number"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  autoComplete="off"
                  width="100%"
                  name="account_number"
                  label="N DA CONTA"
                  placeholder="Informe a conta"
                  value={value}
                  onChange={onChange}
                  error={errors.account_number}
                />
              )}
            />
            <Controller
              control={control}
              name="name_bank"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  autoComplete="off"
                  width="417px"
                  name="name_bank"
                  label="BANCO"
                  placeholder="Informe o banco"
                  value={value}
                  onChange={onChange}
                  error={errors.name_bank}
                />
              )}
            />
          </Flex>

          <Flex gap="2" mt={5}>
            <Controller
              control={control}
              name="comments"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  autoComplete="off"
                  width="100%"
                  name="comments"
                  label="OBSERVAÇÕES"
                  placeholder="..."
                  value={value}
                  onChange={onChange}
                  error={errors.comments}
                />
              )}
            />
          </Flex>
        </Box>
      </Flex>

      <Button
        type="submit"
        bg="green.600"
        color="white"
        alignSelf="flex-end"
        height={10}
        _hover={{
          bg: "green.800",
        }}
        mt="6"
        size="lg"
        isLoading={loading}
      >
        {isModal ? "Salvar" : "Continuar"}
      </Button>
    </Flex>
  );
}
