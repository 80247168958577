import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Input } from "../../components/form/input";
import { InputMask } from "../../components/form/inputMask";
import { IImage, InputFileImage } from "../../components/form/inputFileImage";
import { useEffect, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import { filesize } from "filesize";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../service/api";
import { AllLoading } from "../../components/allLoading";
import { UserProfileDTO } from "../../dtos/UserProfileDTO";
import axios from "axios";

type createFormData = {
  name: string;
  cellphone: string;
  email: string;
  cep: string;
  state: string;
  city: string;
  public_place: string;
  neighborhood: string;
  number: string;
};

const createFormDataSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  cellphone: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  cep: yup.string().required("Campo obrigatório."),
  state: yup.string().required("Campo obrigatório."),
  city: yup.string().required("Campo obrigatório."),
  public_place: yup.string().required("Campo obrigatório."),
  neighborhood: yup.string().required("Campo obrigatório."),
  number: yup.string().required("Campo obrigatório."),
});

export function Profile() {
  const toast = useToast();

  const [imageUser, setImageUser] = useState<IImage>({} as IImage);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  function getImage(info: any) {
    const file = info[0];
    setImageUser({
      file: file,
      name: file.name,
      size: filesize(file.size),
      preview: URL.createObjectURL(file),
    });
  }

  async function getMe() {
    try {
      setLoading(true);
      const response = await api.get("user/show");
      const data: UserProfileDTO = response.data;

      setValue("name", data.name ?? "");
      setValue("email", data.email ?? "");
      setValue("cellphone", data.cellphone ?? "");
      setValue("cep", data.cep ?? "");
      setValue("city", data.city ?? "");
      setValue("state", data.state ?? "");
      setValue("public_place", data.public_place ?? "");
      setValue("neighborhood", data.neighborhood ?? "");
      setValue("number", data.number ?? "");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function getAddress(zipeCodeValue: string) {
    try {
      const zipeCode = zipeCodeValue.replace(".", "").replace("-", "");

      const response = await axios.get(
        `https://viacep.com.br/ws/${zipeCode}/json`
      );
      const data = response.data;
      setValue("city", data.localidade ?? "");
      setValue("state", data.uf ?? "");
      setValue("public_place", data.logradouro ?? "");
      setValue("neighborhood", data.bairro ?? "");
    } catch (error) {}
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      await api.post("user/update", {
        name: value.name,
        cellphone: value.cellphone,
        cep: value.cep,
        number: value.number,
        public_place: value.public_place,
        neighborhood: value.neighborhood,
        city: value.city,
        state: value.state,
      });

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Perfil atualizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível atualizar o perfil.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      bg="gray.50"
      height="100%"
      pb={20}
      pt={10}
    >
      {(loading || isSubmitting) && <AllLoading />}

      <Box
        width="800px"
        display="flex"
        flexDirection="column"
        as="form"
        onSubmit={handleSubmit(handleSend)}
      >
        <Text
          fontWeight="bold"
          textAlign="center"
          mt={5}
          fontSize="26px"
          color="gray.800"
        >
          Perfil
        </Text>

        <Flex alignItems="center" mt={5} justifyContent="center">
          {!imageUser.file && (
            <Flex display="none" width={200}>
              <InputFileImage onChange={getImage} />
            </Flex>
          )}
          {imageUser.file && (
            <Flex width={200} height="180px" flexDirection="column">
              <Flex direction="column" alignItems="center">
                <Avatar size="2xl" name="E-tank" src={imageUser.preview} />

                <Flex width="170px" flexDirection="column">
                  <Button
                    colorScheme="teal"
                    variant="outline"
                    mt={2}
                    size="sm"
                    fontSize="sm"
                    onClick={() => {
                      setImageUser({} as IImage);
                    }}
                    leftIcon={<Icon fontSize="16px" as={RiCloseFill} />}
                  >
                    Remover
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>

        <Stack mt={5} spacing={4}>
          <Input
            size="md"
            label="Nome:"
            placeholder="Informe o nome"
            {...register("name")}
            error={errors.name}
          />
          <Input
            size="md"
            label="Email:"
            type="email"
            placeholder="Informe o email"
            isDisabled
            {...register("email")}
            error={errors.email}
          />
          <Flex gap={2}>
            <InputMask
              size="md"
              label="Celular:"
              placeholder="Informe o celular"
              mask="(99)99999-99999"
              {...register("cellphone")}
              error={errors.cellphone}
            />
            <InputMask
              size="md"
              label="CEP:"
              placeholder="Informe o CEP"
              mask="99999-999"
              {...register("cep")}
              error={errors.cep}
              onBlur={(e) => {
                let zipecodeValueName = e.currentTarget.value;
                if (zipecodeValueName.length >= 8) {
                  getAddress(zipecodeValueName);
                }
              }}
            />
          </Flex>

          <Input
            size="md"
            label="Rua:"
            placeholder="Informe o endereço"
            {...register("public_place")}
            error={errors.public_place}
          />

          <Flex gap={2}>
            <Input
              size="md"
              label="Município:"
              placeholder="Informe o Município"
              {...register("city")}
              error={errors.city}
            />
            <Input
              size="md"
              label="UF:"
              placeholder="UF"
              textAlign="center"
              {...register("state")}
              error={errors.state}
            />
          </Flex>

          <Flex gap={2}>
            <Input
              size="md"
              label="Número:"
              placeholder="Informe o número"
              {...register("number")}
              error={errors.number}
            />
            <Input
              size="md"
              label="Bairro:"
              placeholder="UF"
              {...register("neighborhood")}
              error={errors.neighborhood}
            />
          </Flex>
        </Stack>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          _hover={{
            bg: "green.800",
          }}
          mt="6"
          size="lg"
          alignSelf="flex-end"
        >
          Atualizar
        </Button>
      </Box>
    </Box>
  );
}
