import {
  Button,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../components/form/input";

import LogoEtank from "../../../assets/Logo-e-tank-grande.png";
import { Navigate, useNavigate } from "react-router-dom";
import { InputPassword } from "../../../components/form/inputPassword";
import { useAuth } from "../../../hooks/useAuth";

type SignInFormData = {
  email: string;
  password: string;
};

const signInFormSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  password: yup.string().required("Senha é obrigatório."),
});

export function Login() {
  const toast = useToast();
  const navigate = useNavigate();

  const { signIn, isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(signInFormSchema) });

  if (isAtuhLoading) return;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSignIn: SubmitHandler<SignInFormData> = async (value) => {
    try {
      await signIn(value);
      navigate("/home");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Login",
        description: "Não foi possível realizar o login.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
      <Flex
        as="form"
        width="100%"
        maxWidth={450}
        p="8"
        borderRadius={8}
        flexDir="column"
        onSubmit={handleSubmit(handleSignIn)}
      >
        <Image src={LogoEtank} width={200} alt="Etank" />

        <Text fontWeight="bold" mt={5} fontSize="36px" color="gray.800">
          Acesse a plataforma
        </Text>

        <Text color="gray.600">
          Faça login ou registre-se para começar a utilizar o E-tank Provider
          ainda hoje.
        </Text>

        <Stack mt={5} spacing={4}>
          <Input
            label="Email"
            type="email"
            error={errors.email}
            {...register("email")}
            placeholder="Informe o email"
          />

          <InputPassword
            type="password"
            label="Senha"
            linkText="Esqueceu a senha?"
            {...register("password")}
            error={errors.password}
            placeholder="Informe a senha"
          />
        </Stack>

        <Button
          isLoading={isSubmitting}
          type="submit"
          bg="green.600"
          color="white"
          _hover={{
            bg: "green.800",
          }}
          mt="6"
          size="lg"
        >
          Entrar
        </Button>

        <Flex mt={5} alignItems="center" justifyContent="center">
          <Text>
            Ainda não tem uma conta?{" "}
            <Link href="/cadastro" color="green.600" fontWeight="bold">
              Inscreva-se
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
