import { Box, Button, Flex, Text } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm, Controller } from "react-hook-form";

import { InputLine } from "../../../../components/form/InputLine";
import { InputSelect } from "../../../../components/form/inputSelect";
import { InputPrice } from "../../../../components/form/inputPrice";

type createUpdatePriceFormData = {
  data_update: string;
  type_update: string;
  indication: string;
  motive: string;
  observation?: string;
};

export interface createUpdatePriceData {
  data_update: string;
  type_update: string;
  indication: number;
  motive: string;
  observation?: string;
}

const createUpdatePriceFormSchema = yup.object().shape({
  data_update: yup.string().required("Campo obrigatório."),
  type_update: yup.string().required("Campo obrigatório."),
  indication: yup.string().required("Campo obrigatório."),
  motive: yup.string().required("Campo obrigatório."),
  observation: yup.string(),
});

type Props = {
  callBackSuccess: (data: createUpdatePriceData) => void;
};

export function FormInfo({ callBackSuccess }: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(createUpdatePriceFormSchema) });

  const handleCreate: SubmitHandler<createUpdatePriceFormData> = async (
    value
  ) => {
    let indication = value.indication.replace(".", "").replace(",", ".");
    callBackSuccess({
      data_update: value.data_update,
      type_update: value.type_update,
      indication: Number(indication),
      motive: value.motive,
      observation: value.observation,
    });
  };

  return (
    <Flex
      px={10}
      py={5}
      width="100%"
      flexDirection="column"
      as="form"
      onSubmit={handleSubmit(handleCreate)}
    >
      <Flex flex={1} alignItems="center" justifyContent="space-between">
        <Box p="4" bg="green.50" height={260} borderRadius={8} width="879px">
          <Text fontWeight="bold" mb="4" color="green.800">
            INFORMAÇÕES DO CADASTRO:
          </Text>

          <Flex gap="2">
            <Controller
              control={control}
              name="data_update"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  type="date"
                  autoComplete="off"
                  width="100%"
                  name="data_update"
                  label="DATA ATUALIZAÇÃO"
                  placeholder="Informe a data de atualização"
                  value={value}
                  onChange={onChange}
                  error={errors.data_update}
                />
              )}
            />

            <Controller
              control={control}
              name="type_update"
              render={({ field: { onChange, value } }) => (
                <InputSelect
                  width="100%"
                  label="TIPO DE ATUALIZAÇÃO:"
                  name="type_update"
                  placeholder="Selecione a unidade"
                  options={[
                    {
                      label: "Acréscimo",
                      value: "accrual",
                    },
                    {
                      label: "Redução",
                      value: "reduction",
                    },
                  ]}
                  onChange={(item) => {
                    onChange(item.value);
                  }}
                  error={errors.type_update}
                />
              )}
            />

            <Controller
              control={control}
              name="indication"
              render={({ field: { onChange, value } }) => (
                <InputPrice
                  width="100%"
                  name="indication"
                  label="INDICE"
                  precision="2"
                  value={value}
                  onChange={onChange}
                  error={errors.indication}
                />
              )}
            />
          </Flex>

          <Flex mt={5} gap="2" justifyItems="flex-start">
            <Box>
              <Controller
                control={control}
                name="motive"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    width="278px"
                    label="MOTIVO:"
                    name="motive"
                    placeholder="Selecione o motivo da atualição"
                    options={[
                      { label: "Reajuste ANP", value: "Reajuste ANP" },
                      {
                        label: "Reajuste Distribuidor",
                        value: "Reajuste Distribuidor",
                      },
                      {
                        label: "Reajuste Fornecedor",
                        value: "Reajuste Fornecedor",
                      },
                      {
                        label: "Correção Inflação",
                        value: "Correção Inflação",
                      },
                      { label: "Reajuste Interno", value: "Reajuste Interno" },
                      { label: "Outros", value: "Outros" },
                    ]}
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                    error={errors.motive}
                  />
                )}
              />
            </Box>

            <Controller
              control={control}
              name="observation"
              render={({ field: { onChange, value } }) => (
                <InputLine
                  autoComplete="off"
                  width="100%"
                  name="observation"
                  label="OBSERVAÇÕES"
                  placeholder="Observações"
                  onChange={onChange}
                  value={value}
                  error={errors.observation}
                />
              )}
            />
          </Flex>
        </Box>
      </Flex>

      <Button
        type="submit"
        bg="green.600"
        color="white"
        alignSelf="flex-end"
        height={10}
        _hover={{
          bg: "green.800",
        }}
        mt="6"
        size="lg"
      >
        Continuar
      </Button>
    </Flex>
  );
}
