import { Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm } from "react-hook-form";

import LogoEtank from "../../../assets/Logo-e-tank-grande.png";
import { Input } from "../../../components/form/input";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { api } from "../../../service/api";

type authFormData = {
  email: string;
};

const authFormDataSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
});

export function RecoverPassword() {
  const navigate = useNavigate();

  const { isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(authFormDataSchema) });

  if (isAtuhLoading) return;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSend: SubmitHandler<authFormData> = async (value) => {
    try {
      await api.post("/recoverpassword", {
        email: value.email,
      });

      navigate(`/atualizar-senha/${value.email}`);
    } catch (error) {
      navigate(`/atualizar-senha/${value.email}`);
    }
  };

  return (
    <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
      <Flex
        as="form"
        width="100%"
        maxWidth={450}
        p="8"
        borderRadius={8}
        flexDir="column"
        onSubmit={handleSubmit(handleSend)}
      >
        <Image src={LogoEtank} width={200} alt="Etank" />

        <Text fontWeight="bold" mt={5} fontSize="36px" color="gray.800">
          Recuperação de senha
        </Text>

        <Text color="gray.600">
          Entre com seu e-mail cadastrado na plataforma
        </Text>

        <Stack mt={5} spacing={4}>
          <Input
            label="Email"
            type="email"
            {...register("email")}
            error={errors.email}
            placeholder="Informe o email"
          />
        </Stack>

        <Button
          isLoading={isSubmitting}
          type="submit"
          bg="green.600"
          color="white"
          _hover={{
            bg: "green.800",
          }}
          mt="6"
          size="lg"
        >
          Recuperar senha
        </Button>

        <Flex mt={5} alignItems="center" justifyContent="center">
          <Link href="/login" color="green.600" fontWeight="bold">
            Voltar
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}
