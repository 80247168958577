import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { api } from "../../../service/api";
import {
  FormCreditClient,
  createCreditFormData,
} from "../create/step/form-credit-client";
import { useState } from "react";

type Props = {
  id: string;
  is_open: boolean;
  onClose: () => void;
};
export function ModalCreateCredit({ is_open, onClose, id, ...rest }: Props) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  async function sendCredit(data: createCreditFormData) {
    try {
      await api.post(`contract/credit/create/${id}`, data);

      toast({
        position: "top-right",
        title: "Crédito",
        description: "Crédito adicionado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Crédito",
        description: "Não foi possível adicionar crédito.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal isOpen={is_open} onClose={onClose} isCentered {...rest} size="4xl">
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader>Adicionar créditos</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <FormCreditClient
              isModal
              loading={loading}
              callBackSuccess={(data) => {
                sendCredit(data);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
