import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import { Footer } from "../footer";

type Props = {
  children: React.ReactNode;
  path?: string;
};
export function AuthTemplate({ children, path }: Props) {
  return (
    <Flex direction="column">
      <Header />

      <Flex direction="column" position="absolute" width="100%" mt={"80px"}>
        <Flex width="100%" my="6" px="10" mx="auto">
          <Sidebar path={path} />
          <Box
            bg="white"
            width="full"
            borderWidth={1}
            borderColor="green.400"
            minH="80vh"
            boxShadow="base"
          >
            {children}
          </Box>
        </Flex>

        <Box mb={15} px="10">
          <Footer />
        </Box>
      </Flex>
    </Flex>
  );
}
