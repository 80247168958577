import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import { calcPriceProductContract, formatCurrency } from "../../../utils/utils";
import { ProductContractItemDTO } from "../../../dtos/ProductContractItemDTO";

type Props = {
  id: string;
  is_open: boolean;
  discount: number;
  onClose: () => void;
};
export function ModalProductContract({
  id,
  is_open,
  onClose,
  discount,
  ...rest
}: Props) {
  const [products, setProducts] = useState<ProductContractItemDTO[]>([]);
  const [loading, setLoading] = useState(true);

  async function getProducts() {
    try {
      setLoading(true);
      const response = await api.get(`contract/product/list/${id}`);
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function updateProduct(isCheck: boolean, pd: ProductContractItemDTO) {
    try {
      setLoading(true);

      const response = await api.put(`contract/product/update/${id}`, {
        products_office: [
          {
            id: pd.produc_contract ? pd.produc_contract.id : null,
            product_furnisher_id: pd.id,
            discount: discount,
            status: isCheck ? "active" : "disabled",
          },
        ],
      });
      setProducts(response.data);
    } catch (error) {
      setLoading(false);
    } finally {
      getProducts();
    }
  }

  function isCheckedProduct(pd: ProductContractItemDTO) {
    if (pd.produc_contract && pd.produc_contract.status === "active") {
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (is_open && id) {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, is_open]);

  return (
    <>
      <Modal isOpen={is_open} onClose={onClose} isCentered {...rest} size="5xl">
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader>Produtos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={50}
                  w={50}
                />
              </Box>
            )}
            {!loading && (
              <TableContainer>
                <Table variant="striped" colorScheme="gray" size="sm">
                  <Thead>
                    <Tr bg="green.600" height="10">
                      <Th color="white"></Th>
                      <Th color="white">NOME COMERCIAL</Th>
                      <Th color="white" textAlign="center">
                        UND
                      </Th>
                      <Th color="white" textAlign="center">
                        QUANTIDADE
                      </Th>
                      <Th color="white" textAlign="center">
                        PREÇO ATUAL
                      </Th>
                      <Th color="white" textAlign="center">
                        % DE DESCONTO
                      </Th>
                      <Th color="white" textAlign="center">
                        NOVO PREÇO
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {products.map((item, index) => (
                      <Tr key={index}>
                        <td>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="40px"
                          >
                            <Checkbox
                              size="lg"
                              colorScheme="green"
                              isChecked={isCheckedProduct(item)}
                              onChange={(e) => {
                                //updatelChecked(false, e.target.checked, item);
                                updateProduct(e.target.checked, item);
                              }}
                            />
                          </Box>
                        </td>
                        <Td>{item.name}</Td>
                        <Td textAlign="center">{item.unit}</Td>
                        <Td textAlign="center">
                          {formatCurrency(item.quantity)}
                        </Td>
                        <Td textAlign="center">
                          {formatCurrency(Number(item.price))}
                        </Td>
                        <Td textAlign="center">
                          {formatCurrency(item.produc_contract?.discount ?? 0)}%
                        </Td>
                        <Td textAlign="center">
                          {formatCurrency(
                            calcPriceProductContract(
                              Number(item.price),
                              item.produc_contract?.discount ?? 0
                            )
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
