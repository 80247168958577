import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../components/headerCard";
import { RiArrowLeftSLine, RiCloseFill } from "react-icons/ri";
import { InputSelect } from "../../../components/form/inputSelect";
import { InputLine } from "../../../components/form/InputLine";
import { InputPrice } from "../../../components/form/inputPrice";
import { useEffect, useState } from "react";
import {
  IImage,
  InputFileImage,
} from "../../../components/form/inputFileImage";
import { filesize } from "filesize";
import { api } from "../../../service/api";
import { CategoryGenericDTO } from "../../../dtos/CategoryGenericDTO";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type createFormData = {
  category: string;
  product_id: string;
  product_name: string;
  unit: string;
  quantity: string;
  price: string;
  fabricator: string;
};

const createFormSchema = yup.object().shape({
  category: yup.string().required("Campo obrigatório."),
  product_id: yup.string().required("Campo obrigatório."),
  product_name: yup.string().required("Campo obrigatório."),
  unit: yup.string().required("Campo obrigatório."),
  quantity: yup.string().required("Campo obrigatório."),
  price: yup.string().required("Campo obrigatório."),
  fabricator: yup.string().required("Campo obrigatório."),
});

export function CreateProductServices() {
  const [imageProduct, setImageProduct] = useState<IImage>({} as IImage);
  const toast = useToast();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormSchema) });

  const [categoryGenericData, setCategoryGenericData] = useState<
    CategoryGenericDTO[]
  >([]);
  const [loadingCategoryGeneric, setLoadingCategoryGeneric] = useState(false);

  const [productGenericData, setProductGenericData] = useState<
    CategoryGenericDTO[]
  >([]);
  const [loadingProductGeneric, setLoadingProductGeneric] = useState(false);

  async function getCategoryGeneric() {
    try {
      setLoadingCategoryGeneric(true);
      const response = await api.get("category/product/generic/list");

      const data = response.data;

      const newDate: CategoryGenericDTO[] = [];

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        newDate.push({
          label: element.name,
          value: element.id,
        });
      }

      setCategoryGenericData(newDate);
    } catch (error) {
    } finally {
      setLoadingCategoryGeneric(false);
    }
  }

  async function getProductGeneric(filter?: any) {
    try {
      setLoadingProductGeneric(true);
      const response = await api.get("product/generic/list");

      const data = response.data;

      const newDate: CategoryGenericDTO[] = [];

      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        if (filter) {
          if (filter.value === element.category_product_generic_id) {
            newDate.push({
              label: element.name_generic,
              value: element.id,
            });
          }
        } else {
          newDate.push({
            label: element.name_generic,
            value: element.id,
          });
        }
      }

      setProductGenericData(newDate);
    } catch (error) {
    } finally {
      setLoadingProductGeneric(false);
    }
  }

  function getImage(info: any) {
    const file = info[0];
    setImageProduct({
      file: file,
      name: file.name,
      size: filesize(file.size),
      preview: URL.createObjectURL(file),
    });
  }

  const handleCreate: SubmitHandler<createFormData> = async (value) => {
    try {
      const data = new FormData();

      data.append("product_generic_id", value.product_id);
      data.append("name", value.product_name);
      data.append("unit", value.unit);
      data.append(
        "quantity",
        value.quantity.replace(".", "").replace(",", ".")
      );
      data.append("price", value.price.replace(".", "").replace(",", "."));

      data.append("fabricate", value.fabricator);

      if (imageProduct.file) {
        data.append("file", imageProduct.file, imageProduct.name);
      }

      await api.post("/product/create", data);

      toast({
        position: "top-right",
        title: "Produto",
        description: "Produto cadastrado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/produtos-servicos");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Não foi possível cadastrar o produto.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getCategoryGeneric();
    getProductGeneric();
  }, []);

  return (
    <Box>
      <HeaderCard
        title="CADASTRO DE PRODUTOS/SERVIÇOS"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/produtos-servicos"
      />

      <Flex
        as="form"
        px={10}
        py={5}
        direction="column"
        onSubmit={handleSubmit(handleCreate)}
        margin="auto"
      >
        <Flex flex={1} alignItems="center" justifyContent="center">
          <Box p="4" bg="green.50" height={260} borderRadius={8} width="879px">
            <Text fontWeight="bold" mb="4" color="green.800">
              INFORMAÇÕES DO CADASTRO:
            </Text>

            <Flex gap="2">
              <Controller
                control={control}
                name="category"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    isLoading={loadingCategoryGeneric}
                    width="230px"
                    label="CATEGORIA:"
                    name="cateogry"
                    placeholder="Selecione a categoria"
                    options={categoryGenericData}
                    onChange={(item) => {
                      getProductGeneric(item);
                      onChange(item.value);
                    }}
                    error={errors.category}
                  />
                )}
              />

              <Controller
                control={control}
                name="product_id"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    isLoading={loadingProductGeneric}
                    width="300px"
                    label="NOME GENÉRICO:"
                    name="product"
                    placeholder="Selecione o produto base"
                    options={productGenericData}
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                    error={errors.product_id}
                  />
                )}
              />

              <Controller
                control={control}
                name="product_name"
                render={({ field: { onChange, value } }) => (
                  <InputLine
                    width="300px"
                    name="product_name"
                    label="NOME COMERCIAL"
                    placeholder="Informe o nome comercial do produto"
                    value={value}
                    onChange={onChange}
                    error={errors.product_name}
                  />
                )}
              />
            </Flex>

            <Flex gap={2} px={1} alignItems="flex-start" mt={5}>
              <Controller
                control={control}
                name="unit"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    width="230px"
                    label="UNIDADE:"
                    name="unit"
                    placeholder="Selecione a unidade"
                    options={[
                      { label: "CM", value: "CM" },
                      { label: "GR", value: "GR" },
                      { label: "KG", value: "KG" },
                      { label: "LT", value: "LT" },
                      { label: "M", value: "M" },
                      { label: "M2", value: "M2" },
                      { label: "M3", value: "M3" },
                      { label: "MIL", value: "MIL" },
                      { label: "ML", value: "ML" },
                      { label: "MM", value: "MM" },
                      { label: "PAR", value: "PAR" },
                      { label: "PÇ", value: "PÇ" },
                      { label: "TON", value: "TON" },
                      { label: "UND", value: "UND" },
                    ]}
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                    error={errors.unit}
                  />
                )}
              />

              <Controller
                control={control}
                name="quantity"
                render={({ field: { onChange, value } }) => (
                  <InputPrice
                    width="145px"
                    name="quantity"
                    label="QUANTIDADE"
                    value={value}
                    onChange={onChange}
                    error={errors.quantity}
                  />
                )}
              />

              <Controller
                control={control}
                name="price"
                render={({ field: { onChange, value } }) => (
                  <InputPrice
                    width="145px"
                    name="price"
                    label="PREÇO DE VENDA"
                    value={value}
                    onChange={onChange}
                    error={errors.price}
                  />
                )}
              />

              <Controller
                control={control}
                name="fabricator"
                render={({ field: { onChange, value } }) => (
                  <InputLine
                    name="fabricator"
                    label="FABRICANTE/DISTRIBUIDORA"
                    placeholder="Informe o fabricante"
                    width="300px"
                    onChange={onChange}
                    value={value}
                    error={errors.fabricator}
                  />
                )}
              />
            </Flex>
          </Box>

          <Box
            p="4"
            bg="green.50"
            display="none"
            width="350px"
            height={260}
            borderRadius={8}
          >
            <Text fontWeight="bold" mb="4" color="green.800">
              IMAGEM DO PRODUTO/SERVIÇO:
            </Text>
            <Flex alignItems="center" justifyContent="center">
              {!imageProduct.file && <InputFileImage onChange={getImage} />}
              {imageProduct.file && (
                <Flex width="100%" height="180px">
                  <Flex direction="column">
                    <Image
                      src={imageProduct.preview}
                      width="170px"
                      height="170px"
                      alt="Etank"
                    />
                  </Flex>

                  <Flex ml={5} flexDirection="column">
                    <Text noOfLines={1} maxWidth="120px">
                      {imageProduct.name}
                    </Text>
                    <Text fontSize="xs" color="gray.600" fontWeight="bold">
                      {imageProduct.size}
                    </Text>
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      mt={2}
                      size="sm"
                      fontSize="sm"
                      onClick={() => {
                        setImageProduct({} as IImage);
                      }}
                      leftIcon={<Icon fontSize="16px" as={RiCloseFill} />}
                    >
                      Remover
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>

        <Flex
          width="879px"
          margin="auto"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            type="submit"
            bg="green.600"
            color="white"
            alignSelf="flex-end"
            height={10}
            _hover={{
              bg: "green.800",
            }}
            mt="6"
            size="lg"
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
