import { Box, Flex, SimpleGrid, Spinner, Text } from "@chakra-ui/react";

import ReactApexChart from "react-apexcharts";
import { FilterHome } from "./filter";

import { useDailyVolume } from "./hooks/useDailyVolume";
import { useVolumeGeneral } from "./hooks/useVolumeGeneral";
import { useCostGeneral } from "./hooks/useCostGeneral";
import { HeaderCard } from "../../components/headerCard";

export function Home() {
  const { categories, dataList, loading, error } = useDailyVolume();

  const {
    categories: optionsVolumeGeneral,
    dataList: dataVolumeGeneral,
    loading: loadingVolumeGeneral,
  } = useVolumeGeneral();

  const {
    categories: optionsCost,
    dataList: dataCost,
    loading: loadingCost,
  } = useCostGeneral();

  return (
    <Box>
      <HeaderCard isFilter>
        <Box height="160px" flexDirection="column">
          <Text fontWeight="bold" mt={5} fontSize="26px" color="white">
            Filtros
          </Text>
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <FilterHome />
          </Flex>
        </Box>
      </HeaderCard>

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={5}
        py={5}
      >
        <Box p="8" bg="green.50" borderRadius={8} pb="4">
          <Text fontWeight="bold" mb="4" color="green.800">
            Valor diário por produto
          </Text>
          <Box minHeight={200}>
            {!loading && categories && !error && dataList && (
              <ReactApexChart
                options={categories}
                series={dataList.series}
                type="area"
                height={200}
              />
            )}

            {loading && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={100}
                  w={100}
                />
              </Box>
            )}

            {!loading && error && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Text>Sem dados de relatórios</Text>
              </Box>
            )}
          </Box>
        </Box>
      </SimpleGrid>

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={5}
        mb={5}
      >
        <Box p="8" bg="green.50" borderRadius={8}>
          <Text fontWeight="bold" mb="4" color="green.800">
            Volume geral por produto
          </Text>
          <Box>
            {!loadingVolumeGeneral &&
              optionsVolumeGeneral &&
              dataVolumeGeneral && (
                <ReactApexChart
                  options={optionsVolumeGeneral}
                  series={dataVolumeGeneral.series}
                  type="bar"
                  height={200}
                />
              )}

            {loadingVolumeGeneral && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={100}
                  w={100}
                />
              </Box>
            )}

            {!loadingVolumeGeneral && !dataVolumeGeneral.series && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Text>Sem dados de relatórios</Text>
              </Box>
            )}
          </Box>
        </Box>

        <Box p="8" bg="green.50" borderRadius={8}>
          <Text fontWeight="bold" mb="4" color="green.800">
            Custo geral por produto
          </Text>
          <Box>
            {!loadingCost && optionsCost && dataCost && (
              <ReactApexChart
                options={optionsCost}
                series={dataCost.series}
                type="bar"
                height={200}
              />
            )}

            {loadingCost && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={100}
                  w={100}
                />
              </Box>
            )}

            {!loadingCost && !dataCost.series && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Text>Sem dados de relatórios</Text>
              </Box>
            )}
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
