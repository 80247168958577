import {
  Box,
  Flex,
  IconButton,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../components/headerCard";
import { InputFilter } from "../../../components/filter/inputFilter";
import { InputFilterSelect } from "../../../components/filter/inputFilterSelect";
import { useEffect, useState } from "react";
import { OptionsDTO } from "../../../dtos/OptionsDTO";
import { api } from "../../../service/api";
import { RequestsIssuedDTO } from "../../../dtos/RequestsIssuedDTO";
import {
  URL_WEB,
  formatCurrency,
  formatedDateTimer,
} from "../../../utils/utils";
import { useAuth } from "../../../hooks/useAuth";
import { RiCloseFill, RiFileExcel2Fill, RiFilterLine } from "react-icons/ri";

export function RequestsIssued() {
  const { user } = useAuth();

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  const [dataCompany, setDataCompany] = useState<OptionsDTO[]>([]);
  const [dataProduct, setDataProduct] = useState<OptionsDTO[]>([]);

  const [dataRequisition, setDataRequisition] = useState<RequestsIssuedDTO[]>(
    []
  );

  const [companyId, setCompanyId] = useState("");
  const [productId, setProductId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  async function getFilter() {
    try {
      setLoadingFilter(true);
      const response = await api.get("requistion/report/filter");

      const companyData = response.data.companies;
      const productData = response.data.products;

      const newDataCompany: OptionsDTO[] = [];
      const newDataProduct: OptionsDTO[] = [];

      for (let index = 0; index < companyData.length; index++) {
        const element = companyData[index];
        newDataCompany.push({
          label: element.company_name + " - " + element.cnpj,
          value: element.id,
        });
      }

      for (let index = 0; index < productData.length; index++) {
        const element = productData[index];
        newDataProduct.push({
          label: element.product_name,
          value: element.product_name,
        });
      }

      setDataCompany(newDataCompany);
      setDataProduct(newDataProduct);
    } catch (error) {
    } finally {
      setLoadingFilter(false);
    }
  }

  async function getRequistions() {
    try {
      setLoading(true);
      const response = await api.get(
        `requistion/report/data?office_branch_id=${companyId}&furnisher_office_product_id=${productId}&start_date=${startDate}&end_date=${endDate}`
      );
      setDataRequisition(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function handleFilter() {
    getRequistions();
  }

  function handleClear() {
    window.location.reload();
  }

  useEffect(() => {
    getRequistions();
    getFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <HeaderCard isFilter>
        <Box height="160px" flexDirection="column">
          <Text fontWeight="bold" mt={5} fontSize="26px" color="white">
            Filtros
          </Text>
          <Flex mt={1} gap="2">
            <InputFilterSelect
              isLoading={loadingFilter}
              label="CLIENTE:"
              name="client_id"
              options={dataCompany}
              width="350px"
              placeholder="Selecione o cliente"
              onChange={(item) => {
                setCompanyId(item.value);
              }}
            />
            <InputFilterSelect
              isLoading={loadingFilter}
              label="PRODUTO:"
              name="product_id"
              options={dataProduct}
              width="350px"
              placeholder="Selecione o produto"
              onChange={(item) => {
                setProductId(item.value);
              }}
            />
            <InputFilter
              type="date"
              label="DATA INÍCIO:"
              name="start_date"
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              value={startDate}
            />
            <InputFilter
              type="date"
              label="DATA FINAL:"
              name="end_date"
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              value={endDate}
            />
            {(companyId || productId || startDate || endDate) && (
              <>
                <IconButton
                  ml={3}
                  mt={7}
                  aria-label="Filtro"
                  icon={<RiFilterLine />}
                  onClick={handleFilter}
                />
                <IconButton
                  mt={7}
                  aria-label="Limpar"
                  colorScheme="red"
                  variant="outline"
                  onClick={handleClear}
                  icon={<RiCloseFill />}
                />
                <IconButton
                  mt={7}
                  aria-label="Excel"
                  colorScheme="teal"
                  variant="outline"
                  as="a"
                  target="_blank"
                  href={`${URL_WEB}/furnisher/report/requisition?user_id=${user.id}&office_branch_id=${companyId}&furnisher_office_product_id=${productId}&start_date=${startDate}&end_date=${endDate}`}
                  icon={<RiFileExcel2Fill />}
                />
              </>
            )}
          </Flex>
        </Box>
      </HeaderCard>

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {dataRequisition.length > 0 && !loading && (
          <Box px="4" borderRadius={8} height={500} overflow="scroll">
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      DATA
                    </Th>
                    <Th color="white" textAlign="center">
                      N REQ
                    </Th>
                    <Th color="white" textAlign="center" display="none">
                      N CUPOM
                    </Th>
                    <Th color="white" textAlign="center">
                      PLACA
                    </Th>
                    <Th color="white" textAlign="center">
                      TIPO FROTA
                    </Th>
                    <Th color="white" textAlign="center">
                      ODÔMETRO
                    </Th>
                    <Th color="white" textAlign="center">
                      PROD./SERVIÇO
                    </Th>
                    <Th color="white" textAlign="center">
                      QTDE
                    </Th>
                    <Th color="white" textAlign="center">
                      V. UNIT
                    </Th>
                    <Th color="white" textAlign="center">
                      TOTAL
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataRequisition.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">
                        {formatedDateTimer(item.end_date)}
                      </Td>
                      <Td textAlign="center">{item.code_requisition}</Td>
                      <Td textAlign="center" display="none">
                        {item.document_number}
                      </Td>
                      <Td textAlign="center">{item.plaque}</Td>
                      <Td textAlign="center">{item.name}</Td>
                      <Td textAlign="center">{item.end_odometer}</Td>
                      <Td textAlign="center">{item.product_name}</Td>
                      <Td textAlign="center">{item.quantity_applied}</Td>
                      <Td textAlign="center">
                        R$ {formatCurrency(item.unitary_price)}
                      </Td>
                      <Td textAlign="center">
                        R$ {formatCurrency(item.total_price)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
}
