import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Flex,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { HeaderCard } from "../../../components/headerCard";
import { MultiStep } from "../../../components/multiStep";
import { useState } from "react";
import { FormDataClient, createClientFormData } from "./step/form-data-client";
import {
  FormCreditClient,
  createCreditFormData,
} from "./step/form-credit-client";
import { FormTableProducts, ProductsInfo } from "./step/form-table-products";
import { ResumeClient } from "./step/resume-client";

export function CreateClients() {
  const [step, setStep] = useState(1);
  const [isCredit, setIsCredit] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [show, setShow] = useState(false);

  const [dataForm, setDataForm] = useState<createClientFormData>(
    {} as createClientFormData
  );

  const [dataCredit, setDataCredit] = useState<createCreditFormData>(
    {} as createCreditFormData
  );

  const [dataProduct, setDataProduct] = useState<ProductsInfo[]>([]);

  const handleToggle = () => setShow(!show);

  return (
    <Box>
      <HeaderCard
        title="CADASTRO DE CLIENTE"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/atualizar-precos"
      />

      <Flex justifyContent="center">
        <Box>
          <Collapse in={isBlock} animateOpacity>
            <Alert
              ml={10}
              status="info"
              mt={5}
              width="879px"
              variant="subtle"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              textAlign="center"
              height="auto"
            >
              <AlertTitle mb={1}>
                Ao ativar bloqueio automático, o sistema vai bloquear novas
                requisições quando:
              </AlertTitle>
              <Collapse startingHeight={20} in={show}>
                <AlertDescription maxWidth="sm">
                  <OrderedList textAlign="start">
                    <ListItem>O contrato vencer</ListItem>
                    <ListItem>Quando a data do desconto chegar ao fim</ListItem>
                    <ListItem>Quando o crédito acabar</ListItem>
                  </OrderedList>
                </AlertDescription>
              </Collapse>
              <Button size="sm" onClick={handleToggle} mt="1rem">
                Mostre {show ? "Menos" : "Mais"}
              </Button>
            </Alert>
          </Collapse>

          <Box mt={5} ml={10} width={300}>
            <MultiStep size={isCredit ? 4 : 3} currentStep={step} />
          </Box>
          {step === 1 && (
            <FormDataClient
              isBlock={setIsBlock}
              typePayment={(type) => {
                setIsCredit(type === "advance_credit");
              }}
              callBackSuccess={(data) => {
                setDataForm(data);
                setStep(2);
              }}
            />
          )}

          {step === 2 && isCredit && (
            <FormCreditClient
              callBackSuccess={(data) => {
                setDataCredit(data);
                setStep(3);
              }}
            />
          )}

          {((step === 3 && isCredit) || (!isCredit && step === 2)) && (
            <FormTableProducts
              officeId={dataForm.office_branch_id}
              discount={dataForm.discount}
              callBackSuccess={(data) => {
                setDataProduct(data);
                setStep(isCredit ? 4 : 3);
              }}
            />
          )}

          {((step === 4 && isCredit) || (!isCredit && step === 3)) && (
            <ResumeClient
              products={dataProduct}
              clientData={dataForm}
              creditData={dataCredit}
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
}
