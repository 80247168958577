import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input";
import { FieldError } from "react-hook-form";

type Props = {
  name: string;
  label?: string;
  error?: FieldError;
  value?: string;
  width?: string;
  precision?: string;
  onChange?: (value: any) => void;
};

export function InputPrice({
  value,
  onChange,
  label,
  name,
  error,
  width = "100%",
  precision = "3",
}: Props) {
  const [inFocus, setInFocus] = useState(false);

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel color="gray.800" mb={-0.5} htmlFor={name}>
          {label}
        </FormLabel>
      )}

      <Box mt="4.5px">
        <CurrencyInput
          decimalSeparator=","
          thousandSeparator="."
          precision={precision}
          style={{
            backgroundColor: "transparent",
            borderRadius: 0,
            borderBottomWidth: 2,
            borderBottomColor:
              inFocus && !error ? "#69B0B9" : !!error ? "#E53E3E" : "#475569",
            fontSize: "14px",
            fontFamily: "Titillium Web",
            width,
            outline: "none",
            textAlign: "center",
            boxShadow: `0px 1px 0px 0px ${
              inFocus && !error
                ? "#69B0B9"
                : !!error
                ? "#E53E3E"
                : "transparent"
            }`,
          }}
          onFocus={(e: any) => {
            setInFocus(true);
          }}
          onBlur={() => {
            setInFocus(false);
          }}
          value={value}
          onChangeEvent={onChange}
        />
      </Box>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
}
