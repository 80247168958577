import { Flex, IconButton } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RiCloseFill, RiFilterLine } from "react-icons/ri";
import { OptionsDTO } from "../../../dtos/OptionsDTO";
import { InputFilterSelect } from "../../../components/filter/inputFilterSelect";
import { InputFilter } from "../../../components/filter/inputFilter";
import { api } from "../../../service/api";

export function FilterHome() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataCompany, setDataCompany] = useState<OptionsDTO[]>([]);
  const [dataProduct, setDataProduct] = useState<OptionsDTO[]>([]);

  const company_id = searchParams.get("company_id");
  const product_id = searchParams.get("product_id");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const status_filter = searchParams.get("status");

  const [companyId, setCompanyId] = useState(company_id ?? "");
  const [productId, setProductId] = useState(product_id ?? "");
  const [startDate, setStartDate] = useState(start_date ?? "");
  const [endDate, setEndDate] = useState(end_date ?? "");
  const [status, setStatus] = useState(status_filter ?? "");

  async function getFilter() {
    try {
      setLoadingFilter(true);
      const response = await api.get("requistion/report/filter");

      const companyData = response.data.companies;
      const productData = response.data.products;

      const newDataCompany: OptionsDTO[] = [];
      const newDataProduct: OptionsDTO[] = [];

      for (let index = 0; index < companyData.length; index++) {
        const element = companyData[index];
        newDataCompany.push({
          label: element.company_name + " - " + element.cnpj,
          value: element.id,
        });
      }

      for (let index = 0; index < productData.length; index++) {
        const element = productData[index];
        newDataProduct.push({
          label: element.product_name,
          value: element.product_name,
        });
      }

      setDataCompany(newDataCompany);
      setDataProduct(newDataProduct);
    } catch (error) {
    } finally {
      setLoadingFilter(false);
    }
  }

  useEffect(() => {
    getFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilter() {
    setSearchParams((state) => {
      if (companyId) {
        state.set("company_id", companyId);
      } else {
        state.delete("company_id");
      }

      if (productId) {
        state.set("product_id", productId);
      } else {
        state.delete("product_id");
      }

      if (startDate) {
        state.set("start_date", startDate);
      } else {
        state.delete("start_date");
      }

      if (endDate) {
        state.set("end_date", endDate);
      } else {
        state.delete("end_date");
      }

      if (status) {
        state.set("status", status);
      } else {
        state.delete("status");
      }

      return state;
    });
  }

  function handleClear() {
    setSearchParams((state) => {
      state.delete("company_id");
      setCompanyId("");

      state.delete("product_id");
      setProductId("");

      state.delete("start_date");
      setStartDate("");

      state.delete("end_date");
      setEndDate("");

      state.delete("status");
      setStatus("");
      return state;
    });

    window.location.reload();
  }

  return (
    <Flex gap="2">
      <InputFilterSelect
        isLoading={loadingFilter}
        label="CLIENTE:"
        name="client_id"
        options={dataCompany}
        width="350px"
        placeholder="Selecione o cliente"
        onChange={(item) => {
          setCompanyId(item.value);
        }}
      />
      <InputFilterSelect
        isLoading={loadingFilter}
        label="PRODUTO:"
        name="product_id"
        options={dataProduct}
        width="350px"
        placeholder="Selecione o produto"
        onChange={(item) => {
          setProductId(item.value);
        }}
      />

      <InputFilter
        width="150px"
        type="date"
        label="DATA INÍCIO:"
        name="start_date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
      />
      <InputFilter
        width="150px"
        type="date"
        label="DATA FINAL:"
        name="end_date"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
      />

      {(companyId || productId || status || startDate || endDate) && (
        <>
          <IconButton
            ml={3}
            mt={7}
            aria-label="Filtro"
            icon={<RiFilterLine />}
            onClick={handleFilter}
          />
          <IconButton
            mt={7}
            aria-label="Limpar"
            colorScheme="red"
            variant="outline"
            onClick={handleClear}
            icon={<RiCloseFill />}
          />
        </>
      )}
    </Flex>
  );
}
