import { Navigate, Route, Routes } from "react-router-dom";
import { RecoverPassword } from "../pages/auth/recoverPassword";
import { Login } from "../pages/auth/login";
import { Home } from "../pages/home";
import { AuthRoute } from "./auth-route";
import { ProductServices } from "../pages/productServices/list";
import { CreateProductServices } from "../pages/productServices/create";
import { CreateUpdatePrice } from "../pages/updatePrice/create";
import { NotFound } from "../pages/notFound";
import { UpdatePriceList } from "../pages/updatePrice/list";
import { CreateClients } from "../pages/clients/create";
import { ClientsList } from "../pages/clients/list";
import { ListCreditClient } from "../pages/clients/credit";
import { RequestsIssued } from "../pages/report/requestsIssued";
import { UpdatePassword } from "../pages/auth/updatePassword";
import { RegisterAccount } from "../pages/auth/registerAccount";
import { RegisterAccountSuccess } from "../pages/auth/registerAccount/success";
import { Profile } from "../pages/profile";
import { ListUsers } from "../pages/users/list";
import { CreateUser } from "../pages/users/create";
import { UpdateUser } from "../pages/users/edit";
import { PrivacyPolicy } from "../pages/privacyPolicy";

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/recuperacao-senha" element={<RecoverPassword />} />
      <Route path="/atualizar-senha/:email" element={<UpdatePassword />} />
      <Route path="/cadastro" element={<RegisterAccount />} />
      <Route path="/cadastro/sucesso" element={<RegisterAccountSuccess />} />
      <Route
        path="/home"
        element={
          <AuthRoute path="/home">
            <Home />
          </AuthRoute>
        }
      />

      <Route
        path="/produtos-servicos"
        element={
          <AuthRoute path="/produtos-servicos">
            <ProductServices />
          </AuthRoute>
        }
      />

      <Route
        path="/produtos-servicos/novo"
        element={
          <AuthRoute path="/produtos-servicos">
            <CreateProductServices />
          </AuthRoute>
        }
      />

      <Route
        path="/atualizar-precos"
        element={
          <AuthRoute path="/atualizar-precos">
            <UpdatePriceList />
          </AuthRoute>
        }
      />

      <Route
        path="/atualizar-precos/novo"
        element={
          <AuthRoute path="/atualizar-precos">
            <CreateUpdatePrice />
          </AuthRoute>
        }
      />

      <Route
        path="/clientes/novo"
        element={
          <AuthRoute path="/clientes">
            <CreateClients />
          </AuthRoute>
        }
      />

      <Route
        path="/clientes"
        element={
          <AuthRoute path="/clientes">
            <ClientsList />
          </AuthRoute>
        }
      />

      <Route
        path="/clientes/creditos/:id"
        element={
          <AuthRoute path="/clientes">
            <ListCreditClient />
          </AuthRoute>
        }
      />

      <Route
        path="/requisicoes-atendidas"
        element={
          <AuthRoute path="/requisicoes-atendidas">
            <RequestsIssued />
          </AuthRoute>
        }
      />

      <Route
        path="/usuarios"
        element={
          <AuthRoute path="/usuarios">
            <ListUsers />
          </AuthRoute>
        }
      />

      <Route
        path="/criar-usuario"
        element={
          <AuthRoute path="/usuarios">
            <CreateUser />
          </AuthRoute>
        }
      />

      <Route
        path="/atualizar-usuario/:id"
        element={
          <AuthRoute path="/usuarios">
            <UpdateUser />
          </AuthRoute>
        }
      />

      <Route
        path="/perfil"
        element={
          <AuthRoute path="/perfil">
            <Profile />
          </AuthRoute>
        }
      />

      <Route path="/politica/privacidade" element={<PrivacyPolicy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
