import { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import { useSearchParams } from "react-router-dom";
import { api } from "../../../service/api";
import { formatCurrency } from "../../../utils/utils";

interface IFormatDataChart {
  series: IDataChart[];
}

interface IDataChart {
  name: string;
  data: Array<number>;
}

export function useVolumeGeneral() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<IFormatDataChart>(
    {} as IFormatDataChart
  );

  const [categories, setCategories] = useState<ApexOptions>();

  const [searchParams] = useSearchParams();

  const company_id = searchParams.get("company_id") ?? "";
  const product_id = searchParams.get("product_id") ?? "";
  const start_date = searchParams.get("start_date") ?? "";
  const end_date = searchParams.get("end_date") ?? "";

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `dash/requisition/volume/list?company_id=${company_id}&product_id=${product_id}&start_date=${start_date}&end_date=${end_date}`
      );
      const dataValue = response.data;
      const data: IFormatDataChart = getFormatDataChart(dataValue.values);
      setDataList(data);

      setCategories(getOptions(dataValue.categories));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id, product_id, start_date, end_date]);

  return {
    dataList,
    categories,
    loading,
    error,
    refetch: fetchData,
  };
}

function getFormatDataChart(iFormatDataChart: IFormatDataChart) {
  return iFormatDataChart;
}

function getOptions(categories: Array<string>) {
  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },

    colors: ["#69B0B9"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return (formatCurrency(Number(val)) as string) ?? "0";
        },
      },
    },
    xaxis: {
      categories,
      labels: {
        show: true,
        formatter: function (val) {
          return (formatCurrency(Number(val)) as string) ?? "0";
        },
      },
    },
  };
  return options;
}
