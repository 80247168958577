import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../components/headerCard";
import { RiCloseFill } from "react-icons/ri";
import React, { useEffect, useState } from "react";
import { ProductDTO } from "../../../dtos/productDTO";
import { api } from "../../../service/api";

export function ProductServices() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [productInfo, setProductInfo] = useState<ProductDTO>({} as ProductDTO);

  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [productData, setProductData] = useState<ProductDTO[]>([]);

  const cancelRef = React.useRef<any>(null);

  async function deleteProdcut(productId: string) {
    try {
      setLoadingDelete(true);
      await api.delete(`product/delete/${productId}`);
      toast({
        position: "top-right",
        title: "Produto",
        description: "Produto removido com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Não foi possível remover o produto.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingDelete(false);
      onClose();
      setProductInfo({} as ProductDTO);
      getProducts();
    }
  }

  async function getProducts() {
    try {
      setLoading(true);
      const response = await api.get("product/listAll");
      setProductData(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Box>
      <HeaderCard
        title="LISTA DE PRODUTOS/SERVIÇOS"
        titleButton="Novo cadastro"
        link="/produtos-servicos/novo"
      />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {productData.length > 0 && !loading && (
          <Box p="4" borderRadius={8} height={500}>
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white">CATEGORIA</Th>
                    <Th color="white">NOME COMERCIAL</Th>
                    <Th color="white" textAlign="center">
                      UND
                    </Th>
                    <Th color="white" textAlign="center">
                      QUANTIDADE
                    </Th>
                    <Th color="white">FABRICANTE</Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {productData.map((item, index) => (
                    <Tr key={index}>
                      <Td>
                        {item.product_generic.category_product_generic.name}
                      </Td>
                      <Td>{item.name}</Td>
                      <Td textAlign="center">{item.unit}</Td>
                      <Td textAlign="center">{item.quantity}</Td>
                      <Td>{item.fabricate && item.fabricate}</Td>
                      <Td textAlign="center" width="10">
                        {/* <Button size="sm" fontSize="sm">
                          <Icon fontSize="16px" as={RiPencilFill} />
                        </Button> */}
                        <Button
                          size="sm"
                          ml={1}
                          fontSize="sm"
                          onClick={() => {
                            setProductInfo(item);
                            onOpen();
                          }}
                        >
                          <Icon fontSize="16px" as={RiCloseFill} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remover produto/serviço
            </AlertDialogHeader>

            <AlertDialogBody>
              {!loadingDelete && (
                <Text>
                  Deseja remover o produto/serviço:{" "}
                  <strong>{productInfo?.name}</strong>
                </Text>
              )}

              {loadingDelete && (
                <Flex width="100%" alignItems="center" justifyContent="center">
                  <Spinner
                    color="green.600"
                    emptyColor="white"
                    thickness="4px"
                    size="xl"
                    h={50}
                    w={50}
                  />
                </Flex>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!loadingDelete && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      deleteProdcut(productInfo?.id);
                    }}
                  >
                    Sim
                  </Button>
                  <Button ml={3} onClick={onClose}>
                    Nāo
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
